import cn from 'classnames';
import { useWindowWidth } from 'src/hooks/useWindowWidth';
import { PortalModal } from 'src/layout/PortalModal';
import { Button } from 'src/uikit/Button';
import { Variant } from 'src/uikit/Button/constants';
import { ChevronLeftIcon } from 'src/uikit/icons';

import {
  ContentType,
  InfoType,
  ListGridType,
  ListType,
  ProductsType,
  SolutionsType,
} from '../../types';
import { BurgerButton } from '../BurgerButton';
import { List } from '../List';
import { Products, ProductsSkeleton } from '../Products';
import s from './ModalMenu.module.scss';

interface ModalMenuProps {
  className?: string;
  content: {
    id?: string;
    infoBlock?: InfoType;
    type: ContentType;
    links?: { title: string; text: string; link: string }[][];
    catalogItems?: ProductsType;
    solutions?: SolutionsType;
    list?: ListType;
    gridColumns?: ListGridType;
  };

  onClickForClose(): void;
  onClickOnSameLink(): void;
  showSubheader?: boolean;
  onBurgerClickHandler: () => void;
  isBurgerOpen: boolean;
  headerHeight: number;
}

export function ModalMenu({
  className = '',
  content,
  onClickForClose,
  onClickOnSameLink,
  showSubheader = false,
  isBurgerOpen,
  onBurgerClickHandler,
  headerHeight,
}: ModalMenuProps) {
  const {
    windowWidth,
    breakpoints: { MD },
  } = useWindowWidth();
  const wrapperInfoClassName = content.list;

  return (
    <PortalModal
      portalClassName={s.modal}
      overlayClassName={s.overlay}
      onClickForClose={onClickForClose}
      withoutDefaultCloseIcon
    >
      <div
        className={cn(s.root, className, {
          [s.withoutSubheader]: !showSubheader,
        })}
        style={windowWidth! > MD ? { top: `${headerHeight - 1}px` } : {}}
      >
        <div
          className={cn(s.wrapper, {
            [s.info]: !!content.infoBlock,
            [s.manyLinks]: content.type === 'catalog',
            [s.staticHeight]: wrapperInfoClassName,
          })}
        >
          {windowWidth && windowWidth <= MD && (
            <div className={s.modalHeader}>
              <Button
                className={s.back}
                onClick={onClickForClose}
                variant={Variant.Tertiary}
                data-qa="menu-back-button"
                analyticsOptions={{
                  action: 'click',
                  clickZone: 'search',
                  clickElement: 'button',
                  clickContent: 'Назад',
                  uniqueId: 'menu-back-button',
                  transitionType: 'inside-link',
                }}
              >
                <ChevronLeftIcon /> Назад
              </Button>{' '}
              <BurgerButton
                onClick={onBurgerClickHandler}
                isBurgerOpen={isBurgerOpen}
              />
            </div>
          )}

          {content.list && content.type === 'list' && (
            <div className={s.listWrapper}>
              <List
                onClickForClose={onClickOnSameLink}
                list={content.list}
                gridColumns={content.gridColumns}
              />
            </div>
          )}
          {content.type === 'catalog' && (
            <>
              {content.solutions?.categories &&
              content.solutions?.items &&
              content.catalogItems?.categories &&
              content.catalogItems?.products ? (
                <Products
                  onClickForClose={onClickOnSameLink}
                  products={{
                    items: content.catalogItems.products,
                    categories: content.catalogItems.categories,
                  }}
                  solutions={{
                    items: content.solutions.items,
                    categories: content.solutions.categories,
                  }}
                />
              ) : (
                <ProductsSkeleton />
              )}
            </>
          )}
        </div>
      </div>
    </PortalModal>
  );
}
