import cn from 'classnames';
import React from 'react';
import { useAnalytics } from 'src/hooks/useAnalytics';

import s from './Submit.module.scss';

export function Submit({
  children,
  disabled,
  className,
}: {
  children: React.ReactNode;
  disabled?: boolean;
  className?: string;
}): JSX.Element {
  const { funnelFormSubmit } = useAnalytics();
  return (
    <button
      className={cn(s.root, className)}
      type="submit"
      disabled={disabled}
      onClick={() => funnelFormSubmit()}
    >
      {children}
    </button>
  );
}
