import { FieldErrors, FieldValues, UseFormRegister } from 'react-hook-form';
import { DataFromFormInputs, FieldsNamesType } from 'src/types';

import { Checkbox } from '../Checkbox';

interface UserAgreementCheckboxesProps {
  agreementName?: 'site_services_consent' | 'online_event_consent';
  checkboxClassName?: string;
  errors: FieldErrors<DataFromFormInputs>;
  fieldsNames?: FieldsNamesType;
  register: UseFormRegister<FieldValues>;
  sendFormFillAnalytics?: (fieldName: string) => void;
  theme?: 'base' | 'purple' | 'dark';
  withSubscribeForMailing?: boolean;
  validationErrorText?: string;
}

const AGREEMENT_SUBSCRIBE_DOCK =
  'https://cdn.cloud.ru/backend/docs/security/consent_advertising.pdf';

const agreementLinks = {
  site_services_consent:
    'https://cdn.cloud.ru/backend/docs/security/consent_site.pdf',
  online_event_consent:
    'https://cdn.cloud.ru/backend/docs/security/consent_webinar.pdf',
};

export function UserAgreementCheckboxes({
  agreementName = 'site_services_consent',
  checkboxClassName,
  errors,
  fieldsNames,
  register,
  sendFormFillAnalytics,
  theme = 'base',
  withSubscribeForMailing = true,
  validationErrorText,
}: UserAgreementCheckboxesProps) {
  const userAgreementDesc = `Я даю <a target="_blank" href="${agreementLinks[agreementName]}">согласие</a> на обработку моих персональных данных в соответствии с условиями <a target="_blank" href="https://cdn.cloud.ru/backend/docs/security/politic.pdf ">политики конфиденциальности</a>`;
  const subscribeForMailingDesc = `Я подтверждаю свое <a target="_blank" href="${AGREEMENT_SUBSCRIBE_DOCK}">согласие</a> на получение рекламных и информационных сообщений`;

  return (
    <>
      <Checkbox
        label={fieldsNames?.userAgreement ?? userAgreementDesc}
        validationErrorText={validationErrorText}
        name={agreementName}
        variant={theme}
        required={true}
        errors={errors}
        validationType="userAgreement"
        register={register}
        className={checkboxClassName}
        onCustomChange={(event) => {
          if (event.currentTarget.value) {
            sendFormFillAnalytics &&
              sendFormFillAnalytics('checkbox agreement');
          }
        }}
      />
      {withSubscribeForMailing && (
        <Checkbox
          label={fieldsNames?.checkboxSubscribe ?? subscribeForMailingDesc}
          validationErrorText={validationErrorText}
          name="subscribeForMailing"
          variant={theme}
          required={false}
          errors={errors}
          register={register}
          className={checkboxClassName}
          onCustomChange={(event) => {
            if (event.currentTarget.value) {
              sendFormFillAnalytics &&
                sendFormFillAnalytics('checkbox reklama');
            }
          }}
        />
      )}
    </>
  );
}
