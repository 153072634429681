import React from 'react';
import { IconProps } from 'src/types';

export function Facebook({ className }: IconProps): JSX.Element {
  return (
    <svg
      width="7"
      height="14"
      viewBox="0 0 7 14"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.02148 7.00498H0V4.80498H2.02148V3.70015C2.02148 1.87761 3.4758 0.400146 5.2698 0.400146H6.35257V2.60015H5.2698C4.6718 2.60015 4.18702 3.09263 4.18702 3.70015V4.80498H6.33354V7.00498H4.18702V13.6001H2.02148V7.00498Z"
      />
    </svg>
  );
}
