import cn from 'classnames';
import { useState } from 'react';
import { Scrollbar } from 'react-scrollbars-custom';
import { useAnalytics } from 'src/hooks/useAnalytics';
import { PortalModal } from 'src/layout/PortalModal';
import { ProductCategory, SolutionCategory } from 'src/types/backendContent';

import { AdditionalLinks } from '../AdditionalLinks';
import { ADDITIONAL_LINKS } from '../Products';
import s from './Filter.module.scss';

function FilterIcon() {
  return (
    <svg
      className={s.icon}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.5 5.83301H17.5"
        stroke="white"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.2084 10H4.79175"
        stroke="white"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.7084 14.166H7.29175"
        stroke="white"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

interface FilterProps {
  activeCategory: ProductCategory | SolutionCategory;
  productsCategories: (ProductCategory & { src?: string })[];
  setActiveCategory: React.Dispatch<
    React.SetStateAction<ProductCategory | SolutionCategory>
  >;
}

export function Filter({
  activeCategory,
  productsCategories,
  setActiveCategory,
}: FilterProps) {
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const { clickAnalytics } = useAnalytics();

  const currentCategories = [...productsCategories];

  const onFilterItemClickHandler = (el: ProductCategory | SolutionCategory) => {
    setActiveCategory(el);
    setIsFilterOpen(false);
    clickAnalytics({
      action: '/',
      clickZone: 'header',
      clickElement: 'textclick',
      clickContent: el.title,
      uniqueId: `layout-header-product-category-${el.title}`,
      transitionType: 'inside-link',
    });
  };

  return (
    <div className={cn(s.root, { [s.active]: isFilterOpen })}>
      <div className={s.head} onClick={() => setIsFilterOpen(true)}>
        <div>
          <span>Категория:</span>{' '}
          {activeCategory?.title && (
            <span dangerouslySetInnerHTML={{ __html: activeCategory?.title }} />
          )}
        </div>
        <FilterIcon />
      </div>

      {isFilterOpen && (
        <PortalModal
          portalClassName={s.modal}
          overlayClassName={s.overlay}
          onClickForClose={() => setIsFilterOpen(false)}
          withoutDefaultCloseIcon
        >
          <div
            className={cn(s.content, {
              [s.manyLinks]: currentCategories.length > 3,
            })}
          >
            <Scrollbar className={s.scrollbar}>
              <div className={s.section}>
                {productsCategories.map((el) => (
                  <div
                    className={cn(s.item, {
                      [s.itemActive]: el.id === activeCategory.id,
                    })}
                    key={el.id}
                    data-click="allclicks"
                    onClick={() => onFilterItemClickHandler(el)}
                  >
                    <div dangerouslySetInnerHTML={{ __html: el.title }} />
                    {el.src && (
                      <img
                        className={s.icon}
                        width={24}
                        height={24}
                        src={el.src}
                        alt="category_icon"
                      />
                    )}
                  </div>
                ))}

                <AdditionalLinks
                  links={ADDITIONAL_LINKS}
                  className={s.filterAdditionalLinks}
                />
              </div>
            </Scrollbar>
          </div>
        </PortalModal>
      )}
    </div>
  );
}
