import React from 'react';
import { IconProps } from 'src/types';

export function Telegram({ className }: IconProps): JSX.Element {
  return (
    <svg
      width="12"
      height="10"
      viewBox="0 0 12 10"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.02329 6.61127C5.14533 5.77138 11.5469 1.18715 10.0396 1.95185L5.97258 4.20759C3.56646 5.60791 3.74126 5.38477 4.25038 7.05146C4.29837 7.21249 4.49859 8.10474 4.65622 8.28587C4.93907 8.61068 5.01979 6.6344 5.02329 6.61127ZM2.77035 5.70502C3.00045 5.79208 2.95055 5.76255 3.02873 6.01796C3.2013 6.58174 3.38182 7.10169 3.55756 7.66121C4.15345 9.55834 4.08004 9.56899 4.92795 8.9081C6.32725 7.81737 6.19759 7.71052 7.00069 8.2536L9.31687 9.87158C9.97568 10.348 10.0103 9.38299 10.1235 8.86609L11.9798 0.385316C12.0208 0.220321 11.9966 0.118646 11.9327 0.0614151C11.7986 -0.0585255 11.4897 0.0178834 11.2418 0.114688L1.372 3.7607C-1.58551 4.82616 0.880037 4.99116 2.77035 5.70502Z"
      />
    </svg>
  );
}
