import { ReactNode } from 'react';
import { LoadingSpinner } from 'src/components/LoadingSpinner';
import { Seo } from 'src/components/Seo';
import { useGtmAddRoute } from 'src/hooks/useGtmAddRoute';
import { useStoreState } from 'src/store/index';
import { CustomTheme } from 'src/types';

import { CookiePop } from './CookiePop';
import { Footer } from './Footer';
import { Header } from './Header';
import s from './Layout.module.scss';
import { UnsupportedTechMessages } from './UnsupportedTechMessages';

function LoadingSpinnerMain() {
  return (
    <div style={{ minHeight: 700, marginTop: 100 }}>
      <LoadingSpinner />
    </div>
  );
}

interface LayoutProps {
  customTheme?: CustomTheme;
  showHeader?: boolean;
  showFooter?: boolean;
  showLayoutBanner?: boolean;
  children: ReactNode;
  withoutSearch?: boolean;
  withoutHeaderShadow?: boolean;
  withoutFooterSubscribe?: boolean;
  withConsultationButton?: boolean;
  staticHeader?: boolean;
}

/**
 * Layout
 *
 */
export function Layout({
  staticHeader = false,
  showHeader = true,
  showFooter = true,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  showLayoutBanner = true,
  children,
  withoutSearch,
  withoutHeaderShadow,
  withoutFooterSubscribe,
  withConsultationButton = true,
}: LayoutProps) {
  const state = useStoreState();
  useGtmAddRoute();

  return (
    <div className={s.root}>
      <Seo />
      <UnsupportedTechMessages />
      {showHeader && (
        <Header
          showLayoutBanner
          withoutSearch={withoutSearch}
          withoutHeaderShadow={withoutHeaderShadow}
          staticHeader={staticHeader}
          withConsultationButton={withConsultationButton}
        />
      )}
      <main className={s.main}>
        {state.loadingStatus === 'success' ? children : <LoadingSpinnerMain />}
      </main>
      {showFooter && <Footer withoutFooterSubscribe={withoutFooterSubscribe} />}
      <CookiePop />
      <div id="portal" />
    </div>
  );
}
