import cn from 'classnames';
import { useRouter } from 'next/router';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import Scrollbar from 'react-scrollbars-custom';
import {
  MessageErrorOnFormSubmit,
  Submit,
  TextArea,
} from 'src/components/FormNew';
import { Link } from 'src/components/Link';
import { backendHost } from 'src/global';
import { useAnalytics } from 'src/hooks/useAnalytics';
import { useGetUserMetaData } from 'src/hooks/useGetUserMetaData';
import { useGtmDataLayer } from 'src/hooks/useGtmDataLayer';
import { useSubmitForm } from 'src/hooks/useSubmitForm';
import { useTryFormErrorsCheck } from 'src/hooks/useTryFormErrorsCheck';
import { PortalModal } from 'src/layout/PortalModal';
import { DataFromFormInputs } from 'src/types';
import { ResponseSearch } from 'src/types/backendContent';
import { Button } from 'src/uikit/Button';
import { Variant } from 'src/uikit/Button/constants';
import { ArrowOutIcon } from 'src/uikit/icons';
import { SearchInput } from 'src/uikit/SearchInput';
import { detectExternalLink } from 'src/utils/detectExternalLink';
import { localeToLang } from 'src/utils/localeToLang';
import { scrollToElementWithId } from 'src/utils/scrollToElementWithId';

import s from './Search.module.scss';

const ModalReport = ({
  searchRequestID,
  setIsVisible,
  value,
}: {
  searchRequestID: ResponseSearch['data']['searchRequestID'];
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
  value: string;
}) => {
  const {
    register,
    handleSubmit,
    trigger,
    formState: { errors, isSubmitting },
  } = useForm<DataFromFormInputs>({
    mode: 'onChange',
  });
  const { sendDataToDataLayer } = useGtmDataLayer();
  const { submitForm, formStatus, setFormStatus, formMessage } =
    useSubmitForm();

  useTryFormErrorsCheck(errors, isSubmitting, 'other');

  const onSubmit = async (data: DataFromFormInputs) => {
    const dataToSend = {
      ...data,
      searchRequestID,
      query: value,
    };
    await submitForm({
      backendPatchInput: '/searchforms/v1/search_message',
      dataFromFormInputs: dataToSend,
      allFormSentFormType: 'other',
      allFormSentIsPopup: true,
      onSuccess: () => {
        sendDataToDataLayer({
          dataLayer: {
            event: 'custom_event',
            event_category: 'search LeadForm',
            event_action: 'formSuccess',
            event_label: 'форма обратной связи поиска',
          },
        });
      },
    });
  };

  return (
    <PortalModal
      onClickForClose={() => setIsVisible(false)}
      className={s.reportWrapper}
      overlayClassName={s.overlay}
      withoutDefaultCloseIcon
    >
      <div className={s.report}>
        {formStatus === 'success' ? (
          <>
            <div className={s.reportTitle}>
              Спасибо! Мы стали чуточку лучше!
            </div>
            <Button
              onClick={() => {
                setIsVisible(false);
                setFormStatus('idle');
              }}
              variant={Variant.Primary}
              fullWidth
              analyticsOptions={{
                action: 'click',
                clickZone: 'header',
                clickElement: 'button',
                clickContent: 'Пожалуйста',
                uniqueId: 'header-search-modal-button',
                transitionType: 'inside-link',
              }}
            >
              Пожалуйста
            </Button>
          </>
        ) : (
          <>
            <div className={s.reportTitle}>
              Сообщить об ошибке в работе поиска
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <TextArea
                label="Комментарий"
                placeholder="Опишите ошибку в работе поиска"
                name="comment"
                errors={errors}
                register={register}
                required
              />
              <Submit disabled={formStatus === 'sending'}>
                <Button
                  className="gtm-click-search-modal"
                  variant={Variant.Primary}
                  fullWidth
                  analyticsOptions={{
                    action: 'click',
                    clickZone: 'header',
                    clickElement: 'button',
                    clickContent: 'Отправить',
                    uniqueId: 'header-search-modal-sending-button',
                    transitionType: 'inside-link',
                  }}
                  onClick={() => trigger()}
                >
                  Отправить
                </Button>
              </Submit>
              {formStatus === 'error' && (
                <MessageErrorOnFormSubmit text={formMessage} />
              )}
            </form>
          </>
        )}
      </div>
    </PortalModal>
  );
};

type ForResultProps = ResponseSearch['data']['results'][0]['data'][0];

interface ResultProps extends ForResultProps {
  categorySlug: string;
  value: string;
  onClick(): void;
}

interface BreadCrumbsProps {
  platform?: string;
  product?: string;
  document?: string;
}

function SeparatorDot() {
  return (
    <svg
      width="12px"
      height="12px"
      viewBox="0 0 24 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={s.breadcrumbsDot}
    >
      <circle cx="12" cy="12" r="3" fill="#A6A9AC" />
    </svg>
  );
}

function Breadcrumbs({ platform, product, document }: BreadCrumbsProps) {
  return (
    <div className={s.breadcrumbsWrapper}>
      <span className={s.breadcrumbsTitle}>
        {platform && (
          <>
            {platform}
            <SeparatorDot />
          </>
        )}

        {product && (
          <>
            {product}
            <SeparatorDot />
          </>
        )}

        {document && (
          <>
            {document}
            <SeparatorDot />
          </>
        )}
      </span>
    </div>
  );
}

function Result({
  title,
  description,
  link,
  document,
  categorySlug,
  platform,
  product,
  onClick,
}: ResultProps) {
  const { locale } = useRouter();
  const lang = localeToLang(locale);

  const isExternalLink = detectExternalLink(link);
  const href = isExternalLink ? link : '/' + lang + link;

  const onLinkClick = () => {
    onClick();
  };

  return (
    <Link
      href={href}
      onClick={onLinkClick}
      className={cn(s.card, 'link')}
      target={isExternalLink ? '_blank' : undefined}
      id={`header-search-result-link-${title}`}
      analyticsOptions={{
        action: href,
        clickZone: 'header',
        clickElement: 'cardclick',
        clickContent: title,
        uniqueId: `header-search-result-link-${title}`,
        transitionType: 'inside-link',
      }}
    >
      <span
        className={s.cardTitle}
        dangerouslySetInnerHTML={{ __html: title }}
      />
      {categorySlug === 'documentation' && (
        <Breadcrumbs
          platform={platform}
          product={product}
          document={document}
        />
      )}
      {description && (
        <p
          className={s.cardDesc}
          dangerouslySetInnerHTML={{ __html: description }}
        />
      )}
    </Link>
  );
}

function Results({
  categories,
  results,
  inputValue,
  onClick,
}: {
  categories: ResponseSearch['data']['categories'];
  results: ResponseSearch['data']['results'];
  inputValue: string;
  onClick(): void;
}) {
  const [amount, setAmount] = useState(0);

  useEffect(() => {
    const resultsAmount = results.reduce(
      (acc, val) => acc + val.data.length,
      0,
    );
    setAmount(resultsAmount);
  }, [results]);

  return (
    <div className={s.results}>
      <div className={s.resultsContent}>
        <Scrollbar className={s.resultsScrollbar} id="scrollbar-header-search">
          {results.map(({ slug, data }, index) => {
            const category = categories.find(
              (category) => category.slug === slug,
            );
            const categorySlug = slug;

            return (
              <div className={s.block} key={slug} id={`search-block-${index}`}>
                <div className={s.blockTitle}>{category?.title}</div>
                {data &&
                  data.map(
                    (
                      {
                        title,
                        description,
                        link,
                        icon,
                        document,
                        platform,
                        product,
                      },
                      index,
                    ) => (
                      <Result
                        title={title}
                        description={description}
                        link={link}
                        icon={icon}
                        document={document}
                        platform={platform}
                        product={product}
                        categorySlug={categorySlug}
                        key={index}
                        onClick={onClick}
                        value={inputValue}
                      />
                    ),
                  )}
              </div>
            );
          })}
        </Scrollbar>
      </div>

      <div className={s.menu}>
        <div className={s.menuTitle}>Поиск в категории</div>
        {categories.map(({ title, slug, count }, index) => (
          <div
            className={cn(s.menuItem, 'link')}
            key={slug}
            onClick={() => {
              scrollToElementWithId('', 0, {
                elementID: `search-block-${index}`,
                scrollWrapperID: 'scrollbar-header-search',
              });
            }}
          >
            <span className={s.menuText}>{title}</span> ({count})
          </div>
        ))}
      </div>

      <Button
        link={`/search?query=${inputValue}`}
        variant={Variant.Tertiary}
        className={s.resultsMore}
        analyticsOptions={{
          action: `/search?query=${inputValue}`,
          clickZone: 'header',
          clickElement: 'textclick',
          clickContent: 'Все результаты',
          uniqueId: 'header-search-more-link',
          transitionType: 'inside-link',
        }}
      >
        Все результаты ({amount}) <ArrowOutIcon />
      </Button>
    </div>
  );
}

export function Search({
  setIsVisible,
  showSubheader = false,
}: {
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
  showSubheader?: boolean;
}) {
  const [isSearching, setIsSearching] = useState(false);
  const [value, setValue] = useState('');
  const [error, setError] = useState(false);
  const [searchData, setSearchData] = useState<ResponseSearch['data'] | null>(
    null,
  );
  const [hasDelay, setHasDelay] = useState(true);
  const [shadow, setShadow] = useState<string>('');
  const userMetaData = useGetUserMetaData();
  const { searchAnalytics } = useAnalytics();

  const [isFormVisible, setIsFormVisible] = useState(false);
  const searchInput = useRef<HTMLInputElement>(null);
  const router = useRouter();

  const handleClose = (event: React.KeyboardEvent) => {
    if (event.key === 'Escape' || event.key === 'Esc') setIsVisible(false);
    searchAnalytics('serp', value, 'close-search');
  };

  const onClickToResultItemHandler = () => setIsVisible(false);

  const handleSubmit = (event: React.KeyboardEvent) => {
    if (value.length >= 2) {
      if (event.key === 'Enter' || event.key === 'Esc') {
        const encodedValue = encodeURIComponent(value);

        router.push(`/search?query=${encodedValue}`);
      }
    }
  };

  useEffect(() => {
    const handleSendQuery = async () => {
      if (value.length >= 2) {
        setIsSearching(true);
        const encodedValue = encodeURIComponent(value);
        if (
          router.query['shadow'] !== undefined &&
          typeof router.query['shadow'] === 'string'
        ) {
          setShadow('&shadow_input=' + router.query['shadow']);
        }

        searchAnalytics('serp', value);

        const res = await fetch(
          `${backendHost}/facade/v1/search?ow=true&q=${encodedValue}&ga_client_id=${userMetaData.gaClientID}&ya_client_id=${userMetaData.yaClientID}&original_utm=${userMetaData.originalUTM}&retain_url=${userMetaData.retainURL}${shadow}`,
        );
        const resJson: ResponseSearch = await res.json();

        if (resJson && resJson.success && resJson.data) {
          setSearchData(resJson.data);
        } else setError(true);
      } else {
        setIsSearching(false);
        setSearchData(null);
      }
    };

    if (hasDelay) {
      const timeOutId = setTimeout(async () => await handleSendQuery(), 1000);
      return () => clearTimeout(timeOutId);
    } else {
      handleSendQuery();
      setHasDelay(true);
    }
  }, [value]);

  const handleChange = (query: string) => {
    query = query.replace(/^\s+|\s(?=\s)/g, '').slice(0, 120);

    setValue(query);
    setError(false);
  };

  const clearBtnClick = () => {
    setValue('');
    setSearchData(null);
    setError(false);
    setIsSearching(false);
  };

  const onOverlayClick = () => {
    setIsVisible(false);
    searchAnalytics('serp', value, 'close-search');
  };

  useEffect(() => {
    if (searchInput.current) searchInput.current.focus();
  }, []);

  return (
    <div
      className={cn(s.root, {
        [s.withoutSubheader]: !showSubheader,
      })}
      onKeyDown={(event: React.KeyboardEvent) => handleClose(event)}
      tabIndex={1}
    >
      <div className={s.overlay} onClick={onOverlayClick} />
      <div className={s.wrapper} data-qa="modal_search_section">
        <SearchInput
          onClickButton={() => {
            const encodedValue = encodeURIComponent(value);
            router.push(`/search?query=${encodedValue}`);
          }}
          onClear={clearBtnClick}
          onKeyDown={handleSubmit}
          withButton
          wrapperClassName={s.searchInputWrapper}
          value={value}
          onValueChange={(value) => handleChange(value)}
        />
        {isSearching && !error && searchData?.categories ? (
          <>
            <div className={s.info}>
              {!searchData.results && (
                <span className={s.title}>
                  По вашему запросу ничего не найдено
                </span>
              )}

              {searchData.suggest && (
                <div className={s.suggestResult}>
                  Возможно, вы имели в виду:{' '}
                  <span
                    className={s.suggestLink}
                    onClick={() => {
                      setHasDelay(false);
                      setValue(searchData.suggest);
                    }}
                  >
                    {searchData.suggest}
                  </span>
                </div>
              )}
              <p className={s.errorText}>
                Если результат поиска не соответствует вашему запросу,{' '}
                <Button
                  onClick={() => setIsFormVisible(true)}
                  className={s.errorTextLink}
                  variant={Variant.Tertiary}
                  data-qa="search-message-form-button"
                  analyticsOptions={{
                    action: 'click',
                    clickZone: 'search',
                    clickElement: 'button',
                    clickContent: 'сообщите',
                    uniqueId: 'header-search-modal-sending-button',
                    transitionType: 'inside-link',
                  }}
                >
                  сообщите
                </Button>{' '}
                нам об ошибке в работе поиска
              </p>
            </div>
            {searchData.results && (
              <Results
                categories={searchData.categories}
                results={searchData.results}
                inputValue={value}
                onClick={onClickToResultItemHandler}
              />
            )}
          </>
        ) : error ? (
          <div className={s.error}>
            <span className={s.errorTitle}>
              По вашему запросу ничего не найдено
            </span>
            <p className={s.errorText}>
              Пожалуйста, проверьте правильность написания или{' '}
              <Button
                onClick={() => setIsFormVisible(true)}
                className={s.errorTextLink}
                variant={Variant.Tertiary}
                data-qa="search-message-form-button"
                analyticsOptions={{
                  action: 'click',
                  clickZone: 'search',
                  clickElement: 'button',
                  clickContent: 'сообщите',
                  uniqueId: 'header-search-modal-sending-button',
                  transitionType: 'inside-link',
                }}
              >
                сообщите
              </Button>{' '}
              нам об ошибке в работе поиска
            </p>
          </div>
        ) : null}
      </div>
      {isFormVisible && (
        <ModalReport
          searchRequestID={searchData ? searchData.searchRequestID : '-'}
          value={value}
          setIsVisible={setIsFormVisible}
        />
      )}
    </div>
  );
}
