import { useEffect, useRef, useState } from 'react';

import { useAnalytics } from './useAnalytics';
import { useIntersectionObserver } from './useIntersectionObserver';

export const useIntersectionObserveForms = () => {
  const { sendAnalytics } = useAnalytics();
  const [isFormVisited, setIsFormVisited] = useState(false);
  const formRef = useRef<HTMLDivElement>(null);
  const [anchorId, setAnchorId] = useState<string>('');
  const entry = useIntersectionObserver(formRef, { freezeOnceVisible: true });
  const isFormVisible = !!entry?.isIntersecting;

  useEffect(() => {
    if (isFormVisible && !isFormVisited) {
      const dataLayerToSend: { [key: string]: string } = {
        event: 'test_event',
        event_category: 'forms',
        event_action: 'formView',
        event_label: 'Funnel-form-view',
        retain_url: window.location.href,
        visit_method: !!anchorId ? 'anchor' : 'scroll',
      };

      if (anchorId) dataLayerToSend.anchorId = anchorId;

      const ymData = {
        'Funnel-form-view': {
          [window.location.href]: {
            [!!anchorId ? 'anchor' : 'scroll']: anchorId,
          },
        },
      };

      sendAnalytics(dataLayerToSend, ymData, 'Funnel-form-view');
      setIsFormVisited(true);
    }
  }, [isFormVisible, isFormVisited, sendAnalytics, anchorId]);

  return { formRef, setAnchorId };
};
