import Head from 'next/head';
import { useRouter } from 'next/router';

interface SeoProps {
  title?: string;
  description?: string;
  image?: string;
  children?: React.ReactNode;
}

const DEFAULT_VALUE = {
  title: 'Облако Cloud.ru — облачный провайдер IaaS/PaaS и ML сервисов',
  description:
    'Cloud.ru (ООО «Облачные технологии») – облачный провайдер IaaS/PaaS и Machine Learning сервисов. Вычислительные ресурсы, хранение данных в облаке, мониторинг и управление, сервисы для Big Data. Тел: +7 495 260-10-82',
  image: '/favicons/logo-180.png',
};

const genCanonicalUrl = (asPath: string) => {
  const currentUrl = `https://cloud.ru${asPath}`;

  let canonicalUrl = currentUrl.split(/[?#]/)[0];

  if (canonicalUrl.endsWith('/'))
    canonicalUrl = canonicalUrl.substring(0, canonicalUrl.length - 1);

  canonicalUrl = canonicalUrl.toLowerCase();

  return canonicalUrl;
};

export function Seo({ title, description, image, children }: SeoProps) {
  const { asPath } = useRouter();
  const canonicalUrl = genCanonicalUrl(asPath);
  //
  const titleToUse = title ? title : DEFAULT_VALUE.title;
  const descriptionToUse = description
    ? description
    : DEFAULT_VALUE.description;
  const imageToUse = image ? image : DEFAULT_VALUE.image;
  //
  const MetaTags = (
    <>
      {/* main */}
      <title>{titleToUse}</title>
      {/* main */}
      {/* meta */}
      <meta key="title" property="title" content={titleToUse} />
      <meta key="description" name="description" content={descriptionToUse} />
      <meta key="author" name="author" content="ООО «Облачные технологии»" />
      {/* meta */}
      {/* OpenGraph */}
      <meta key="og:type" property="og:type" content="website" />
      <meta key="og:title" property="og:title" content={titleToUse} />
      <meta key="og:site_name" property="og:site_name" content={titleToUse} />
      <meta
        key="og:description"
        property="og:description"
        content={descriptionToUse}
      />
      <meta key="og:url" property="og:url" content={canonicalUrl} />
      <meta key="og:image" property="og:image" content={imageToUse} />
      <meta key="og:image:width" property="og:image:width" content="512" />
      <meta key="og:image:height" property="og:image:height" content="512" />
      {/* OpenGraph */}
      {/* SocialMedia */}
      <meta key="vk:image" property="vk:image" content={imageToUse} />
      <meta key="twitter:image" property="twitter:image" content={imageToUse} />
      <meta property="twitter:card" content={imageToUse} />
      {/* SocialMedia */}
    </>
  );
  //
  const Links = (
    <>
      <link key="image-src" rel="image_src" href={imageToUse} />
      <link key="canonical" rel="canonical" href={canonicalUrl} />
    </>
  );
  //
  return (
    <Head>
      {MetaTags}
      {Links}
      {children}
    </Head>
  );
}
