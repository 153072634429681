import { useEffect } from 'react';
import ReactGtmModule from 'react-gtm-module';
import { getDataLayer } from 'src/utils/getDataLayer';

export const useGtmAddRoute = () => {
  useEffect(() => {
    (async () => {
      const dataLayer = await getDataLayer();
      if (!dataLayer || dataLayer.length < 1) return;
      //
      const { location, title } = document;
      const { protocol, hostname, pathname, search } = location;
      const pageUrlToUse = protocol + '//' + hostname + pathname + search;
      const pagePathQueryToUse = pathname + search;
      //
      const allNextRoute = dataLayer.filter((data) => {
        if (data?.event) return data.event === 'nextRoute';
        return false;
      });
      //
      const lastNextRoute = allNextRoute[allNextRoute.length - 1];
      //
      let refpagePathQuery = '';
      if (
        lastNextRoute?.pagePathQuery &&
        typeof lastNextRoute.pagePathQuery === 'string'
      )
        refpagePathQuery = lastNextRoute.pagePathQuery;
      //
      let refpageUrl = '';
      if (lastNextRoute?.pageUrl && typeof lastNextRoute.pageUrl === 'string')
        refpageUrl = lastNextRoute.pageUrl;
      //
      ReactGtmModule.dataLayer({
        dataLayer: {
          event: 'nextRoute',
          pageTitle: title,
          pageType: 'PageView',
          pageUrl: pageUrlToUse,
          refpageUrl: refpageUrl,
          pagePathQuery: pagePathQueryToUse,
          refpagePathQuery: refpagePathQuery,
        },
      });
    })();
  }, []);
};
