import { useAnalytics } from 'src/hooks/useAnalytics';
import { ContactInteractionAnalytics } from 'src/types';

export const ContactsAnalytics = ({
  contact,
  interactionText,
}: Omit<ContactInteractionAnalytics, 'interactionType'>) => {
  const { contactInteractionAnalytics } = useAnalytics();

  const handleCopy = () => {
    contactInteractionAnalytics({
      interactionType: 'copy',
      contact: contact,
      interactionText: interactionText,
    });
  };

  const handleClick = () => {
    contactInteractionAnalytics({
      interactionType: 'click',
      contact: contact,
      interactionText: interactionText,
    });
  };

  return { handleClick, handleCopy };
};
