import cn from 'classnames';
import { Link } from 'src/components/Link';
import { ArrowOut } from 'src/uikit/icons/ContentIcons';
import { Tag } from 'src/uikit/Tag';

import { CardType } from '../../types';
import s from './Card.module.scss';

export function Card({
  icon,
  title,
  link,
  onClickForClose,
  serviceTitle,
  isPlatformCard = false,
  text,
  tags,
}: CardType) {
  const currentIcon = icon ? icon : '/plugs/product-2d-icon-default.svg';

  return (
    <div onClick={onClickForClose}>
      <Link
        href={link}
        className={cn(s.root, 'link', { [s.platformCard]: isPlatformCard })}
        id={`header-card-link-${title}`}
        analyticsOptions={{
          action: link,
          clickZone: 'header',
          clickElement: 'cardclick',
          clickContent: title,
          uniqueId: `header-card-link-${title}`,
          transitionType: 'inside-link',
        }}
      >
        <img
          className={s.icon}
          src={currentIcon}
          alt="Product Icon"
          width={20}
          height={20}
        />

        <div className={s.content}>
          <h4 className={s.service}>
            <span>{serviceTitle}</span>
            <ArrowOut className={s.serviceIcon} />
          </h4>
          <h3 className={s.title} dangerouslySetInnerHTML={{ __html: title }} />
          {text && <p className={s.text}>{text}</p>}

          {tags && tags.length > 0 && (
            <div className={s.tagsList}>
              {tags.map(({ title, appearance }, index) => {
                return (
                  <Tag
                    key={`${title}_${index}`}
                    variant={Tag.variants.Primary}
                    appearance={appearance}
                  >
                    {title}
                  </Tag>
                );
              })}
            </div>
          )}
        </div>
      </Link>
    </div>
  );
}
