import cn from 'classnames';
import { Link } from 'src/components/Link';

import s from './UserAgreement.module.scss';

interface UserAgreementProps {
  buttonTitle: string;
  className?: string;
}

export function UserAgreement({
  buttonTitle,
  className = '',
}: UserAgreementProps) {
  return (
    <div className={cn(s.root, className)}>
      Нажимая кнопку «{buttonTitle}» вы соглашаетесь с
      <Link
        href={'https://cdn.cloud.ru/backend/docs/security/politic.pdf'}
        target="_blank"
        className={s.link}
        id={`user-agreemnt-link-politics-${buttonTitle}`}
        analyticsOptions={{
          action: 'https://cdn.cloud.ru/backend/docs/security/politic.pdf',
          clickZone: 'body',
          clickElement: 'textclick',
          clickContent: buttonTitle,
          uniqueId: `user-agreemnt-link-politics-${buttonTitle}`,
          transitionType: 'inside-link',
        }}
      >
        &nbsp; политикой обработки персональных данных
      </Link>
    </div>
  );
}
