import { getDataLayerFromPage } from './getDataLayerFromPage';
import { wait } from './wait';

type DataLayer = {
  [key: string]: string;
};

export const getDataLayer = async () => {
  let counter = 6;
  let dataLayer: DataLayer[] | undefined = undefined;
  let dataLayerExist = false;
  do {
    counter--;
    await wait(200);
    dataLayer = getDataLayerFromPage();
    if (dataLayer && dataLayer.length > 0) dataLayerExist = true;
  } while (counter > 0 && !dataLayerExist);
  if (!dataLayer || dataLayer.length < 1) return;
  return dataLayer;
};
