import { useRouter } from 'next/router';
import { Catalog, CatalogProduct } from 'src/types/backendContent';
import { localeToLang } from 'src/utils/localeToLang';

interface FilteredProduct {
  valueSearch: string;
  products: Catalog['products'] | [] | null;
}

const filterProductsBySearch = ({ valueSearch, products }: FilteredProduct) => {
  if (!products || products.length < 1) return [];
  if (!valueSearch || !valueSearch.trim()) return products;

  const unicode = (str: string) => str.charCodeAt(0).toString(16).toUpperCase();

  const safeSearch = valueSearch
    .trim()
    .split('')
    .map((el) => {
      if (
        unicode(el) === '2B' || // +
        unicode(el) === '3F' || // ?
        unicode(el) === '28' || // (
        unicode(el) === '29' || // )
        unicode(el) === '2A' || // *
        unicode(el) === '5B' || // [
        unicode(el) === '7C' || // |
        unicode(el) === '5C' // \
      )
        return `\\${el}`;

      return el;
    })
    .join('');

  const regexp = new RegExp(safeSearch, 'i');

  const prevfilteredProducts = products.filter(
    (product) =>
      regexp.test(product.title.trim()) ||
      (product.markTitle && regexp.test(product.markTitle.trim())) ||
      (product.description && regexp.test(product.description.trim())),
  );

  const whoIsMain = (product1: CatalogProduct, product2: CatalogProduct) => {
    if (
      regexp.test(product1.title.trim()) &&
      !regexp.test(product2.title.trim())
    )
      return -1;

    if (
      !regexp.test(product1.title.trim()) &&
      regexp.test(product2.title.trim())
    )
      return 1;

    return 0;
  };

  const whoIsFirstInTitle = (
    product1: CatalogProduct,
    product2: CatalogProduct,
  ) => {
    let val1 = product1.title.toLowerCase().indexOf(safeSearch.toLowerCase());
    let val2 = product2.title.toLowerCase().indexOf(safeSearch.toLowerCase());

    if (val1 === -1) val1 = 999;
    if (val2 === -1) val2 = 999;

    if (val1 > val2) return 1;
    if (val1 < val2) return -1;

    return 0;
  };

  const whoIsFirstIDesc = (
    product1: CatalogProduct,
    product2: CatalogProduct,
  ) => {
    let val1 =
      (product1.description &&
        product1.description.toLowerCase().indexOf(safeSearch.toLowerCase())) ??
      -1;

    let val2 =
      (product2.description &&
        product2.description.toLowerCase().indexOf(safeSearch.toLowerCase())) ??
      -1;

    if (val1 === -1) val1 = 999;
    if (val2 === -1) val2 = 999;

    if (val1 > val2) return 1;
    if (val1 < val2) return -1;

    return 0;
  };

  const filteredProducts = prevfilteredProducts
    .sort((product1, product2) => whoIsFirstIDesc(product1, product2))
    .sort((product1, product2) => whoIsFirstInTitle(product1, product2))
    .sort((product1, product2) => whoIsMain(product1, product2));

  return filteredProducts;
};

export const useProductsSearch = ({
  products,
  valueSearch,
}: {
  products: Catalog['products'];
  valueSearch: string;
}) => {
  const { locale } = useRouter();
  const lang = localeToLang(locale);
  const productsAfterSearch = filterProductsBySearch({ valueSearch, products });

  const messageType = productsAfterSearch.length === 0 ? 'notFound' : 'found';

  const message = { title: '', desc: '' };
  if (messageType === 'found') {
    message.title = lang === 'en' ? 'Filter results' : 'Результаты фильтрации';
    message.desc =
      lang === 'en'
        ? ` Filter results for: «${valueSearch.trim()}»`
        : ` Результаты фильтрации по запросу: «${valueSearch.trim()}»`;
  }

  if (messageType === 'notFound') {
    message.title =
      lang === 'en'
        ? 'No results were found for your search.'
        : 'По вашему запросу ничего не найдено.';
    message.desc =
      lang === 'en'
        ? 'Please check your spelling or try other keywords.'
        : 'Пожалуйста, проверьте правильность написания или попробуйте другие ключевые слова.';
  }

  const searchActive = valueSearch.trim() ? true : false;

  return {
    productsAfterSearch,
    message,
    searchActive,
  };
};
