import cn from 'classnames';
import React, { ChangeEvent } from 'react';
import { FieldValues, UseFormRegister } from 'react-hook-form';
import { useAnalytics } from 'src/hooks/useAnalytics';
import { valSchema } from 'src/utils/valSchema';

import s from './RadioButtons.module.scss';

interface RadioButtonsOptions {
  value: string;
  label: string;
  subLabel?: string;
  tooltip?: string;
}

type InputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> &
  (InputNotRequired | InputRequired);

interface InputBase {
  title: string;
  name: string;
  options: RadioButtonsOptions[];
  errors: any;
  register: UseFormRegister<FieldValues>;
  defaultChecked?: number;
  variant?: 'light' | 'dark';
  wrapperClassName?: string;
  onCustomChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}

interface InputNotRequired extends InputBase {
  required?: false;
  requiredText?: undefined;
}

interface InputRequired extends InputBase {
  required: true;
  requiredText: string;
}

export function RadioButtons({
  title,
  name,
  options,
  errors,
  register,
  requiredText,
  required,
  defaultChecked,
  variant = 'light',
  wrapperClassName = '',
  onCustomChange,
  ...rest
}: InputProps) {
  const { funnelFormInput } = useAnalytics();

  const { onChange, ...restOptions } = {
    ...register(name, {
      required: required ? valSchema.default?.required : false,
    }),
  };
  return (
    <div
      className={cn(s.root, {
        [s.error]: errors && errors[name],
        [s.dark]: variant === 'dark',
      })}
    >
      <div className={s.header}>
        <span className={s.title}>{title}</span>
        {requiredText && <span className={s.reqText}>{requiredText}</span>}
      </div>

      <div className={cn(s.wrapper, wrapperClassName)}>
        {options.map((option, index) => (
          <div className={s.item} key={index}>
            <input
              className={s.input}
              type="radio"
              value={option.value}
              defaultChecked={defaultChecked === index}
              id={name + index}
              {...rest}
              {...restOptions}
              onChange={(event) => {
                onChange(event);
                onCustomChange && onCustomChange(event);
                funnelFormInput();
              }}
            />
            <label className={s.label} htmlFor={name + index}>
              {option.label}
            </label>
          </div>
        ))}
      </div>
      {errors && errors[name] && (
        <span className={s.errorText}>{errors[name].message}</span>
      )}
    </div>
  );
}
