import cn from 'classnames';
import { KeyboardEventHandler } from 'react';
import { useAnalytics } from 'src/hooks/useAnalytics';
import { CrossIcon, SearchIcon } from 'src/uikit/icons';

import { Button } from '../Button';
import { Variant } from '../Button/constants';
import s from './SearchInput.module.scss';

interface SearchInputProps {
  value: string;
  onValueChange: (value: string) => void;
  onClickButton?: () => void;
  withButton?: boolean;
  variant?: 'base' | 'small';
  className?: string;
  placeholder?: string;
  disabled?: boolean;
  wrapperClassName?: string;
  onClear?: () => void;
  onKeyDown?: KeyboardEventHandler<HTMLInputElement> | undefined;
  clickZone?: 'header' | 'search' | 'catalog-search' | 'body' | 'footer';
}

export function SearchInput({
  value,
  onValueChange,
  withButton,
  onClickButton,
  placeholder = 'Поиск',
  className = '',
  wrapperClassName = '',
  variant = 'base',
  disabled = false,
  onKeyDown = undefined,
  onClear,
  clickZone,
}: SearchInputProps) {
  const { clickAnalytics } = useAnalytics();

  return (
    <div
      className={cn(s.wrapper, wrapperClassName, {
        [s.disabled]: disabled,
        [s.withButton]: withButton,
      })}
    >
      <SearchIcon className={s.searchIcon} />
      <input
        type="text"
        className={cn(s.root, className, {
          [s.small]: variant === 'small',
          [s.filled]: value.length !== 0,
        })}
        onChange={(event) => onValueChange(event?.currentTarget.value)}
        onClick={() => {
          clickAnalytics({
            action: 'click',
            clickZone: clickZone ? clickZone : 'body',
            clickElement: 'input',
            clickContent: placeholder,
            uniqueId: `filter-catalog-input-${placeholder}`,
            transitionType: 'inside-link',
          });
        }}
        value={value ?? ''}
        placeholder={placeholder}
        disabled={disabled}
        onKeyDown={onKeyDown}
      />
      {withButton && onClickButton && (
        <Button
          variant={Variant.Tertiary}
          className={s.find}
          onClick={onClickButton}
          analyticsOptions={{
            action: 'click',
            clickZone: clickZone ? clickZone : 'body',
            clickElement: 'button',
            clickContent: 'Найти',
            uniqueId: `search-find-button`,
            transitionType: 'inside-link',
          }}
        >
          найти
        </Button>
      )}

      {!!value && (
        <CrossIcon
          className={s.clearIcon}
          onMouseDown={() => {
            onValueChange('');
            onClear && onClear();
          }}
        />
      )}
    </div>
  );
}
