import React from 'react';

export function UnsupportedTechMessages() {
  return (
    <div>
      <div
        dangerouslySetInnerHTML={{
          __html: `<!--[if lte IE 10]>
                  Ваш браузер не поддерживается. Установите современный браузер, к примеру, Google Chrome.
                <![endif]-->`,
        }}
      />
      <noscript>
        Включите JavaScript в вашем браузере для полноценной работы сайта
      </noscript>
    </div>
  );
}
