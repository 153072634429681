import cn from 'classnames';
import { Fragment } from 'react';
import { LocaleSwitcher } from 'src/components/LocaleSwitcher';
import { Logo } from 'src/components/Logo';
import { SocialButtons } from 'src/components/SocialButtons';
import { Subscribe } from 'src/components/Subscribe';
import { Link } from 'src/uikit/Link';
import { ContactsAnalytics } from 'src/utils/contactsAnalytics';
import { genStringForUrl as transliteration } from 'src/utils/genStringForUrl';

import s from './Footer.module.scss';

type FooterItem = { title: string; data: FooterLinksType[] };

interface FooterLinksType {
  text: string;
  url: string;
  target?: FooterLinkType;
}

enum FooterLinkType {
  Blank = '_blank',
  Self = '_self',
}

interface FooterDataType {
  contacts: FooterItem[];
  mainLinks: FooterItem[];
  lastColumn: FooterItem[];
  secondaryLinks: FooterLinksType[];
}

const footerData: FooterDataType = {
  contacts: [
    {
      title: 'Техническая поддержка',
      data: [
        {
          text: '8 800 444-24-99',
          url: 'tel:+78004442499',
        },
        {
          text: 'support@cloud.ru',
          url: 'mailto:support@cloud.ru',
        },
        {
          text: 'Чат в Telegram',
          url: 'https://t.me/cloudruprovider',
          target: FooterLinkType.Blank,
        },
        {
          text: 'Связаться с нами',
          url: '/consultation',
        },
      ],
    },
    {
      title: 'Офис',
      data: [
        {
          text: '+7 495 260-10-82',
          url: 'tel:+74952601082',
        },
      ],
    },
    {
      title: 'Пресс-служба',
      data: [
        {
          text: 'pr@cloud.ru',
          url: 'mailto:pr@cloud.ru',
        },
      ],
    },
  ],
  mainLinks: [
    {
      title: 'Продукты',
      data: [
        {
          text: 'Виртуальная машина',
          url: '/products/evolution-compute',
        },
        {
          text: 'Кластеры Kubernetes',
          url: '/products/evolution-managed-kubernetes',
        },
        {
          text: 'Объектное хранилище S3',
          url: '/products/evolution-object-storage',
        },
        {
          text: 'Marketplace',
          url: '/marketplace',
        },
        {
          text: 'Зарубежные аналоги',
          url: '/analogi',
        },
        {
          text: 'Другие сервисы',
          url: '/services',
        },
        {
          text: 'Все продукты',
          url: '/products',
        },
      ],
    },
    {
      title: 'Для бизнеса',
      data: [
        {
          text: 'Cloud.ru Evolution',
          url: '/evolution',
        },
        {
          text: 'Cloud.ru Advanced ',
          url: '/advanced',
        },
        {
          text: 'Облако VMware',
          url: '/vmware',
        },
        {
          text: 'Cloud.ru ML Space',
          url: '/mlspace',
        },
        {
          text: 'Решения',
          url: '/solutions',
        },
        {
          text: 'Кейсы',
          url: '/cases',
        },
        {
          text: 'Партнеры',
          url: '/partners',
        },
      ],
    },
  ],
  lastColumn: [
    {
      title: 'Сообщество',
      data: [
        {
          text: 'Блог',
          url: '/blog',
        },
        {
          text: 'Обучение и сертификация',
          url: '/education',
        },
        {
          text: 'Мероприятия',
          url: '/events',
        },
        {
          text: 'Исследования Cloud.ru',
          url: '/issledovanie-oblachnye-tehnologii',
        },
        {
          text: 'Реферальная программа',
          url: '/partners/referral',
        },
      ],
    },
    {
      title: 'Разработчикам',
      data: [
        {
          text: 'Документация',
          url: '/docs/index.html',
          target: FooterLinkType.Blank,
        },
      ],
    },
  ],
  secondaryLinks: [
    {
      text: 'О нас',
      url: '/about',
    },
    {
      text: 'Карьера',
      url: '/career',
    },
    {
      text: 'Новости',
      url: '/news',
    },
    {
      text: 'Контакты',
      url: '/contacts',
    },
    {
      text: 'Юридические документы',
      url: '/documents/',
    },
  ],
};

function FooterLinks({ links }: { links: FooterLinksType[] }) {
  return (
    <ul className={s.links}>
      {links.map((link, index) => {
        let contact: 'email' | 'social' | 'phone' | undefined;
        if (link.url.startsWith('tel:')) {
          contact = 'phone';
        } else if (link.url.startsWith('mailto:')) {
          contact = 'email';
        } else if (link.url.startsWith('https://')) {
          contact = 'social';
        }

        const { handleCopy, handleClick } = contact
          ? ContactsAnalytics({
              contact: contact,
              interactionText: link.text,
            })
          : { handleCopy: () => {}, handleClick: () => {} };

        const id = `layout-footer-link-${transliteration(link.text)}`;

        return (
          <Fragment key={index}>
            <li className={s.link}>
              <Link
                href={link.url}
                className="link"
                data-qa={id}
                id={id}
                target={link.target}
                analyticsOptions={{
                  action: link.url,
                  clickZone: 'footer',
                  clickElement: 'textclick',
                  clickContent: link.text,
                  uniqueId: id,
                  transitionType: 'inside-link',
                }}
                onClick={handleClick}
                onCopy={handleCopy}
              >
                {link.text}
              </Link>
            </li>
          </Fragment>
        );
      })}
    </ul>
  );
}

function FooterContacts({ contacts }: { contacts: FooterItem[] }) {
  return (
    <div className={s.contacts}>
      <div className={s.item} data-qa={'footer-contacts'}>
        {contacts.map((contact, index) => (
          <Fragment key={index}>
            <h3 className={s.itemTitle}>{contact.title}</h3>
            <FooterLinks links={contact.data} />
          </Fragment>
        ))}
      </div>
    </div>
  );
}

function FooterVertical({ items }: { items: FooterItem[] }) {
  return (
    <div className={s.lastColumn}>
      {items.map((item, index) => (
        <div
          className={s.item}
          data-qa={`footer-column-${transliteration(item.title)}`}
          key={index}
        >
          <h3 className={s.itemTitle}>{item.title}</h3>
          <FooterLinks links={item.data} />
        </div>
      ))}
    </div>
  );
}

function FooterHorizontal({ columns }: { columns: FooterItem[] }) {
  return (
    <>
      {columns.map((item, index) => (
        <div
          className={cn(s.item, s.itemLinks)}
          key={index}
          data-qa={`footer-column-${transliteration(item.title)}`}
        >
          <h3 className={s.itemTitle}>{item.title}</h3>
          <FooterLinks links={item.data} />
        </div>
      ))}
    </>
  );
}

function FooterTop({ data }: { data: FooterDataType }) {
  return (
    <div className={s.top}>
      <FooterContacts contacts={data.contacts} />

      <FooterHorizontal columns={data.mainLinks} />
      <FooterVertical items={data.lastColumn} />
    </div>
  );
}

function FooterBottom({
  secondaryLinks,
}: {
  secondaryLinks: FooterLinksType[];
}) {
  return (
    <div className={s.bottom}>
      <span
        aria-label="Cloud.ru copyright"
        className={s.copyright}
        data-qa="footer-copyright"
      >
        &copy;&nbsp;{new Date().getFullYear()}&nbsp;Cloud.ru
      </span>

      <div className={s.right}>
        <div className={s.links}>
          <FooterLinks links={secondaryLinks} />
        </div>

        <div className={s.rightWrapper}>
          <SocialButtons
            className={s.socialButtons}
            classNameSocialButton={s.socialButton}
            classNameImgHb={s.socialButtonImgHb}
            classNameImgTg={s.socialButtonImgTg}
            classNameImgVk={s.socialButtonImgVk}
            classNameImgYt={s.socialButtonImgYt}
          />
        </div>
      </div>
    </div>
  );
}

interface FooterProps {
  withoutFooterSubscribe?: boolean;
}

export function Footer({ withoutFooterSubscribe }: FooterProps) {
  return (
    <footer className={s.root}>
      <div className={s.wrapper}>
        <div className={s.header}>
          <Link
            href="/"
            className={s.logoLink}
            data-qa="footer-logo"
            id="layout-footer-logo-link"
            analyticsOptions={{
              action: '/',
              clickZone: 'footer',
              clickElement: 'textclick',
              clickContent: 'Logo',
              uniqueId: 'layout-footer-logo-link',
              transitionType: 'inside-link',
            }}
          >
            <Logo className={s.logo} />
          </Link>
          <LocaleSwitcher lang={'ru'} />
        </div>
        <FooterTop data={footerData} />

        {!withoutFooterSubscribe && (
          <Subscribe
            variant="inline"
            eventLabel="footer"
            className={s.subscribe}
            title="Подпишитесь на дайджест"
          />
        )}

        <FooterBottom secondaryLinks={footerData.secondaryLinks} />
      </div>
    </footer>
  );
}
