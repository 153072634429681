const CloseIcon = ({
  className = '',
  onClick,
}: {
  className?: string;
  onClick?: () => void;
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      className={className}
      onClick={onClick}
    >
      <path
        d="M6.06249 15.1594L4.84058 13.9375L8.77808 9.99999L4.84058 6.06249L6.06249 4.84058L9.99999 8.77808L13.9375 4.84058L15.1594 6.06249L11.2219 9.99999L15.1594 13.9375L13.9375 15.1594L9.99999 11.2219L6.06249 15.1594Z"
        fill="currentColor"
      />
    </svg>
  );
};

export { CloseIcon };
