import cn from 'classnames';

import s from './FormPlug.module.scss';

export function FormPlug({
  colorTheme,
  id,
}: {
  colorTheme?: 'white';
  id?: string;
}) {
  return (
    <p className={cn(s.root, { [s.white]: colorTheme === 'white' })} id={id}>
      Contract for Cloud.ru services is currently available only in Russian. The
      contract in English is in drafting stage
    </p>
  );
}
