const AdvancedPlatform = ({ className }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 4.9345L10 2L18 4.9345V15.0655L10 18L2 15.0655V4.9345ZM10 16.5176L16.4247 14.0661V5.76985L10 3.55535L3.57527 5.76985V14.0661L10 16.5176Z"
        fill="currentColor"
      />
      <path
        d="M9.20703 9.20721H10.8647V10.7928H9.20703V9.20721Z"
        fill="currentColor"
      />
      <path
        d="M9.20703 10.3604H10.8647V10.7928H9.20703V10.3604Z"
        fill="currentColor"
      />
      <path
        d="M11.6577 3.5856H13.2433V11.5856H11.6577V3.5856Z"
        fill="currentColor"
      />
      <path
        d="M11.6577 8.34235H13.2433V11.5856H11.6577V8.34235Z"
        fill="currentColor"
      />
      <path
        d="M6.82886 8.34235H8.41444V15.9099H6.82886V8.34235Z"
        fill="currentColor"
      />
      <path
        d="M6.82886 12.8108H8.41444V15.9099H6.82886V12.8108Z"
        fill="currentColor"
      />
    </svg>
  );
};

export { AdvancedPlatform };
