import { Checkbox } from './Checkbox';
import { CheckboxGroup } from './CheckboxGroup';
import { FileUpload } from './FileUpload';
import { FormPlug } from './FormPlug';
import { Input } from './Input';
import { MessageErrorOnFormSubmit } from './MessageErrorOnFormSubmit';
import { MessageFormSubmitted } from './MessageFormSubmitted';
import { RadioButtons } from './RadioButtons';
import { Select } from './Select';
import { ShadowInput } from './ShadowInput';
import { Submit } from './Submit';
import { TextArea } from './TextArea';
import { UserAgreement } from './UserAgreement';
import { UserAgreementCheckboxes } from './UserAgreementCheckboxes';

export {
  Checkbox,
  CheckboxGroup,
  FileUpload,
  FormPlug,
  Input,
  MessageErrorOnFormSubmit,
  MessageFormSubmitted, // Переписать
  RadioButtons,
  Select,
  ShadowInput,
  Submit,
  TextArea,
  UserAgreement,
  UserAgreementCheckboxes,
};
