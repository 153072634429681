import cn from 'classnames';
import { Link } from 'src/components/Link';
import { useAnalytics } from 'src/hooks/useAnalytics';
import { PortalModal } from 'src/layout/PortalModal';

import { Button } from '../../../../uikit/Button';
import s from './ModalBurger.module.scss';

function MoreIcon() {
  return (
    <svg
      className={s.arrow}
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M10.7156 9.99999L6.77808 6.06249L7.99999 4.84058L13.1594 9.99999L7.99999 15.1594L6.77808 13.9375L10.7156 9.99999Z"
        fill="#26D07C"
      />
    </svg>
  );
}

export function ModalBurger({
  onClickForClose,
  className = '',
  menuItems,
  activeMenuItem,
  onConsultationClickHandler,
  setIsModalMenuOpen,
  setActiveMenuItem,
  showSubheader = false,
  withConsultationButton,
  headerHeight,
  isBurgerOpen,
}: {
  onClickForClose(): void;
  className?: string;
  withConsultationButton?: boolean;
  menuItems: { id: string; title: string; link?: string; target?: string }[];
  activeMenuItem: string | null;
  onConsultationClickHandler(): void;
  setIsModalMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setActiveMenuItem: React.Dispatch<React.SetStateAction<string | null>>;
  showSubheader?: boolean;
  headerHeight: number;
  isBurgerOpen?: boolean;
}) {
  const { clickAnalytics } = useAnalytics();

  const onMenuItemClickHandler = (id?: string) => {
    if (id && activeMenuItem !== id) {
      setIsModalMenuOpen(true);
      setActiveMenuItem(id);
    } else {
      setIsModalMenuOpen(false);
      setActiveMenuItem(null);
    }
  };

  return (
    <PortalModal
      portalClassName={s.modal}
      overlayClassName={s.overlay}
      onClickForClose={onClickForClose}
      withoutDefaultCloseIcon
    >
      <div
        className={cn(s.root, className, {
          [s.withoutSubheader]: !showSubheader,
        })}
        style={{ top: `${headerHeight - 1}px` }}
      >
        <div className={s.col}>
          {menuItems.map(({ id, title, link, target }) => {
            if (link) {
              return (
                <Link
                  data-qa="header_nav_link"
                  onClick={onClickForClose}
                  className={cn(s.menu, 'header-mainMenu', 'link')}
                  href={link}
                  target={target}
                  key={id}
                  id={`header-modalburger-link-${title}`}
                  analyticsOptions={{
                    action: link,
                    clickZone: 'header',
                    clickElement: 'textclick',
                    clickContent: title,
                    uniqueId: `header-modalburger-link-${title}`,
                    transitionType: 'inside-link',
                  }}
                >
                  {title}
                </Link>
              );
            }

            return (
              <div
                onClick={() => {
                  onMenuItemClickHandler(id);
                  clickAnalytics({
                    action: '/',
                    clickZone: 'header',
                    clickElement: 'textclick',
                    clickContent: title,
                    uniqueId: `layout-header-item-${title}`,
                    transitionType: 'inside-link',
                  });
                }}
                data-click="allclicks"
                className={cn(s.menu, 'header-mainMenu')}
                key={id}
              >
                {title}
                <MoreIcon />
              </div>
            );
          })}
        </div>
        {withConsultationButton && (
          <Button
            variant={Button.variants.Outline}
            className={s.button}
            onClick={onConsultationClickHandler}
            data-qa="header_button_consultation"
            analyticsOptions={{
              action: 'click',
              clickZone: 'header',
              clickElement: 'button',
              clickContent: 'Связаться с нами',
              uniqueId: 'header-consultation',
              transitionType: 'inside-link',
            }}
          >
            Связаться с нами
          </Button>
        )}
      </div>
    </PortalModal>
  );
}
