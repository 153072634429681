import cn from 'classnames';
import { ReactNode } from 'react';
import { Link } from 'src/components/Link';
import { ArrowOut } from 'src/uikit/icons/ContentIcons';
import { Tag } from 'src/uikit/Tag';

import { TagType } from '../../types';
import s from './LinkCard.module.scss';

interface LinkCardProps {
  link: string;
  title: string;
  image?: string | ReactNode;
  text?: string;
  tags?: TagType[];
  onClickForClose(): void;
  isButton?: boolean;
  target?: string;
}

export function LinkCard({
  link,
  title,
  text,
  image,
  tags,
  onClickForClose,
  isButton = false,
  target,
}: LinkCardProps) {
  return (
    <Link
      href={link}
      className={cn(s.linkCard, {
        [s.activeCard]: isButton,
      })}
      onClick={onClickForClose}
      id={`header-linkcard-${title}`}
      target={target}
      analyticsOptions={{
        action: link,
        clickZone: 'header',
        clickElement: 'textclick',
        clickContent: title,
        uniqueId: `header-linkcard-link-${title}`,
        transitionType: 'inside-link',
      }}
    >
      {image && (
        <div className={s.linkCardImageWrapper}>
          {typeof image === 'string' ? (
            <img
              src={image}
              width={20}
              height={20}
              alt={title}
              className={s.linkCardImage}
            />
          ) : (
            image
          )}
        </div>
      )}

      <div className={s.linkCardDescription}>
        <h4 className={s.linkCardTitle}>
          <span dangerouslySetInnerHTML={{ __html: title }} />
          <ArrowOut className={s.linkCardTitleIcon} />
        </h4>

        {text && (
          <p
            className={s.linkCardText}
            dangerouslySetInnerHTML={{ __html: text }}
          />
        )}

        {tags && tags.length > 0 && (
          <div className={s.tagsList}>
            {tags.map(({ title, appearance }, index) => {
              return (
                <Tag
                  key={`${title}_${index}`}
                  variant={Tag.variants.Primary}
                  appearance={appearance}
                >
                  {title}
                </Tag>
              );
            })}
          </div>
        )}
      </div>
    </Link>
  );
}
