import cn from 'classnames';
import { useRef, useState } from 'react';
import {
  DeepMap,
  FieldError,
  FieldName,
  SetFieldValue,
  SetValueConfig,
} from 'react-hook-form';
import { Button } from 'src/components/Button';
import { useAnalytics } from 'src/hooks/useAnalytics';
import { DataFromFormInputs } from 'src/types';
import { CloseIcon } from 'src/uikit/icons/CloseIcon';

import s from './FileUpload.module.scss';

type FileUploadProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  label: string;
  name: string;
  errors: DeepMap<any, FieldError>;
  accept?: string;
  multiple?: boolean;
  register: any;
  required?: boolean;
  validationFileSize?: number;
  setValue: (
    name: FieldName<FileList>,
    value: SetFieldValue<DataFromFormInputs>,
    config?: SetValueConfig,
  ) => void;
  onCustomChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const fileValidationFunction = (
  fileList: FileList,
  fileSize: number,
  required: boolean,
) => {
  const uploadedFile = Array.from(fileList);
  if (required === false) if (uploadedFile.length < 1) return true;
  if (uploadedFile.length < 1) return 'Загрузите файл';
  const bigSize = uploadedFile.find((file) => file.size > fileSize);
  if (bigSize)
    return `Слишком большой размер файла "${bigSize.name.slice(
      0,
      20,
    )}....${bigSize.name.split('.').at(-1)}"`;
  return true;
};

export function FileUpload({
  label,
  name,
  errors,
  accept,
  multiple,
  register,
  required = false,
  validationFileSize = 20000000,
  setValue,
  onCustomChange,
  ...rest
}: FileUploadProps) {
  const { funnelFormInput } = useAnalytics();
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { ref, onChangeInput, ...restOptions } = register(name, {
    validate: {
      fileValidation: (fileList: FileList) => {
        return fileValidationFunction(fileList, validationFileSize, required);
      },
    },
  });

  const handleRewriteInputFiles = (updatedFiles: File[]) => {
    const fileList = new DataTransfer();
    updatedFiles.forEach((file) => {
      fileList.items.add(file);
    });
    setValue(name, fileList.files, { shouldValidate: true });
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const filesForUpload = event.target.files;
    if (filesForUpload) {
      const filesWithoutDuplicates = Array.from(filesForUpload).filter(
        (file) => {
          const duplicateFile = uploadedFiles.find(
            (uploadedFile) => file.name === uploadedFile.name,
          );
          return duplicateFile ? null : file;
        },
      );
      let filesToUpload;
      if (multiple) {
        filesToUpload = [...uploadedFiles, ...filesWithoutDuplicates];
      } else {
        filesToUpload = [...filesWithoutDuplicates];
      }
      setUploadedFiles(filesToUpload);
      handleRewriteInputFiles(filesToUpload);
    }
  };

  const handleDeleteFile = (fileName: string) => {
    const updatedFiles = uploadedFiles.filter((file) => file.name !== fileName);
    setUploadedFiles(updatedFiles);
    handleRewriteInputFiles(updatedFiles);
  };

  return (
    <div className={cn(s.root, { [s.error]: errors && errors[name] })}>
      <div className={s.wrapper}>
        <label className={s.label} htmlFor={name}>
          <span className={s.labelName}>{label}</span>
          <input
            ref={(el) => {
              inputRef.current = el;
              ref(el);
            }}
            data-click="funnel-form-input"
            className={s.input}
            name={name}
            style={{ display: 'none' }}
            id={name}
            type="file"
            multiple={multiple}
            accept={accept}
            {...restOptions}
            {...rest}
            onChange={(event) => {
              onCustomChange && onCustomChange(event);
              handleChange(event);
              onChangeInput && onChangeInput(event);
            }}
            onClick={funnelFormInput}
          />
          <Button
            variant="link"
            className={s.button}
            id={`file-unpload-button-${name}`}
            analyticsOptions={{
              action: 'click',
              clickZone: 'body',
              clickElement: 'button',
              clickContent: 'Добавить',
              uniqueId: `file-unpload-button-${name}`,
              transitionType: 'inside-link',
            }}
          >
            Добавить
          </Button>
        </label>
        {uploadedFiles.map((file, index) => (
          <div className={s.name} key={index}>
            <span className={s.nameText}>{file.name}</span>{' '}
            <CloseIcon
              className={s.icon}
              onClick={() => handleDeleteFile(file.name)}
            />
          </div>
        ))}
      </div>
      {errors && errors[name] && (
        <span className={s.errorText}>{errors[name].message}</span>
      )}
    </div>
  );
}
