const Close = ({
  className,
  onClick,
}: {
  className?: string;
  onClick?: () => void;
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      className={className}
      onClick={onClick}
    >
      <path d="M1 11L11 1" stroke="currentColor" strokeWidth="1.6" />
      <path d="M1 1L11 11" stroke="currentColor" strokeWidth="1.6" />
    </svg>
  );
};

export { Close };
