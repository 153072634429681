import cn from 'classnames';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { Link } from 'src/components/Link';
import { Logo } from 'src/components/Logo';
import { backendHost, consoleRedirectUrl } from 'src/global';
import { useAnalytics } from 'src/hooks/useAnalytics';
import { useGtmDataLayer } from 'src/hooks/useGtmDataLayer';
import { useHeaderPosition } from 'src/hooks/useHeaderPosition';
import { useWindowWidth } from 'src/hooks/useWindowWidth';
import {
  Catalog,
  SolutionCategory,
  SolutionsData,
} from 'src/types/backendContent';
import { Button } from 'src/uikit/Button';
import { Variant } from 'src/uikit/Button/constants';
import { Form } from 'src/uikit/Form';
import { AdvancedPlatform } from 'src/uikit/icons/ContentIcons/General/AdvancedPlatform';
import { EvolutionPlatform } from 'src/uikit/icons/ContentIcons/General/EvolutionPlatform';
import { Factory } from 'src/uikit/icons/ContentIcons/General/Factory';
import { Fire } from 'src/uikit/icons/ContentIcons/General/Fire';
import { Mlspace } from 'src/uikit/icons/ContentIcons/General/Mlspace';
import { VM } from 'src/uikit/icons/ContentIcons/General/VM';
import {
  BrowserWindow,
  Cloud,
  CloudUpload,
  Edit,
  File,
  Layers,
  MagnifyingGlass,
  RocketFly,
  Ruble,
  Shield,
  TrainingCourse,
  Webinar,
} from 'src/uikit/icons/ContentIcons/TechIcons';
import { Appearance } from 'src/uikit/Tag/constants';
import { localeToLang } from 'src/utils/localeToLang';
import useSWR from 'swr';

import { useFormatConsoleUrl } from '../../hooks/useFormatConsoleUrl';
import {
  BurgerButton,
  HeaderSearchInput,
  ModalMenu,
  SubHeader,
} from './components';
import { HeaderSearch } from './components/HeaderSearch';
import { ModalBurger } from './components/ModalBurger';
import s from './Header.module.scss';
import { ContentType, MenuItemsType, ProductsType } from './types';

async function fetcher<T>(key: string): Promise<T> {
  const res = await fetch(backendHost + key);
  if (!res.ok) throw new Error(`fetcher`);
  const responce = await res.json();
  if (!responce.success || !responce.data) throw new Error(`fetcher`);
  return responce.data;
}

export const formatLinks = (links: { [key: string]: string }[][]) => {
  const maxLength = Math.max(...links.map((arr) => arr.length));
  const formatted = [];

  for (let i = 0; i < maxLength; i++) {
    for (let j = 0; j < links.length; j++) {
      const value = links[j][i] || {};
      formatted.push(value);
    }
  }

  return formatted;
};

const MENU_ITEMS: MenuItemsType = [
  {
    id: '0',
    title: 'Продукты',
    type: 'catalog' as ContentType,
  },
  {
    id: '1',
    title: 'Цены',
    type: 'list' as ContentType,
    list: [
      {
        title: 'Полезные разделы',
        items: [
          {
            title: 'Калькулятор цен',
            text: 'Соберите свою конфигурацию ресурсов, узнайте ее стоимость и подключите',
            link: '/calculator',
            icon: <Ruble />,
          },
          {
            title: 'Тарифы',
            text: 'Тарифы на услуги и сервисы платформ',
            link: '/documents/tariffs/index.html',
            icon: <File />,
          },
          {
            title: 'Все акции',
            text: 'Узнайте о всех акция и выгодных предложениях Cloud.ru',
            link: '/offers',
            icon: <Fire />,
          },
        ],
      },
      {
        title: 'Акции',
        items: [
          {
            title: 'Дарим до 20 000 бонусов ',
            link: '/offers/free-test',
            icon: 'https://cdn.cloud.ru/backend/carousel/main-carousel/bonuses.png',
            theme: '#99c1ff',
            type: 'offers',
          },
          {
            title: 'Evolution free tier',
            link: '/offers/free-tier',
            icon: 'https://cdn.cloud.ru/backend/carousel/main-carousel/main_slide_2.png',
            theme: '#26d07c',
            type: 'offers',
          },
          {
            title: 'Мигрируйте бесплатно в&nbsp;наше облако',
            link: '/offers/migration',
            icon: 'https://cdn.cloud.ru/backend/carousel/main-carousel/main_slide_1.png',
            theme: '#e1d7f2',
            type: 'offers',
          },
        ],
      },
    ],
  },
  {
    id: '2',
    title: 'Документация',
    type: 'link' as ContentType,
    link: '/docs/index.html',
    target: '_blank',
  },
  {
    id: '3',
    title: 'О компании',
    type: 'list' as ContentType,
    list: [
      {
        title: 'Полезные разделы',
        items: [
          {
            title: 'О нас',
            text: 'Профессиональная поддержка ваших бизнес-задач',
            link: '/about',
            icon: <CloudUpload />,
          },
          {
            title: 'Карьера в Cloud.ru',
            text: 'Станьте частью команды',
            link: '/career',
            target: '_blank',
            icon: <RocketFly />,
          },
          {
            title: 'Новости',
            text: 'Важные события и объявления',
            link: '/news',
            icon: <Layers />,
          },
          {
            title: 'Юридические документы',
            text: 'Договоры, оферта, условия и другое',
            link: '/documents/',
            icon: <File className={s.smallFileSize} />,
          },
          {
            title: 'Контакты',
            text: 'Адрес офиса и способы связи с нами',
            link: '/contacts',
            icon: <Edit />,
          },
        ],
      },
    ],
  },
  {
    id: '4',
    title: 'Для бизнеса',
    type: 'list' as ContentType,
    gridColumns: 'four',
    list: [
      {
        title: 'Полезные разделы',
        items: [
          {
            title: 'Партнерство с Cloud.ru',
            text: 'Максимальный заработок и расширение вашего бизнеса',
            link: '/partners',
            icon: <Cloud />,
          },
          {
            title: 'Кейсы',
            text: 'Как компании создают и развивают бизнес в облаке Cloud.ru',
            link: '/cases',
            icon: <Fire />,
          },
          {
            title: 'Решения',
            text: 'Готовые решения для бизнеса, разработки и других задач',
            link: '/solutions',
            icon: <Factory />,
          },
          {
            title: 'Архитектурный центр',
            text: 'Лучшие практики перехода в облако Cloud.ru',
            link: '/docs/architecture-center/index.html',
            icon: <CloudUpload />,
          },
          {
            title: 'Безопасность',
            text: 'Обеспечиваем высокий уровень безопасности клиентских данных',
            link: '/security',
            icon: <Shield />,
          },
        ],
      },
      {
        title: 'Облачные платформы Cloud.ru',
        items: [
          {
            title: 'Evolution',
            text: 'Публичное российское облако на базе open source',
            link: '/evolution',
            icon: <EvolutionPlatform />,
            tags: [
              {
                title: 'Доступна всем',
                appearance: Appearance['fluorescent-yellow-5'],
              },
            ],
          },
          {
            title: 'Advanced',
            text: '60+ IaaS- и PaaS-сервисов для развития инфраструктуры',
            link: '/advanced',
            icon: <AdvancedPlatform />,
          },
          {
            title: 'ML Space',
            text: 'Суперкомпьютеры и ML для создания приложений',
            link: '/mlspace',
            icon: <Mlspace />,
            tags: [
              {
                title: 'Только для юрлиц',
                appearance: Appearance['purple-5'],
              },
            ],
          },
          {
            title: 'Облако VMware',
            text: 'Облачная инфраструктура на базе технологий VMware',
            link: '/vmware',
            icon: <VM />,
            tags: [
              {
                title: 'Только для юрлиц',
                appearance: Appearance['purple-5'],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: '5',
    title: 'Сообщество',
    type: 'list' as ContentType,
    gridColumns: 'four',
    list: [
      {
        title: 'Полезные разделы',
        items: [
          {
            title: 'Блог',
            text: 'Всё про облачные технологии и AI c пользой для бизнеса',
            link: '/blog',
            icon: <BrowserWindow />,
          },
          {
            title: 'Обучение и сертификация',
            text: 'Развивайтесь в сфере облачных технологий и подтверждайте опыт',
            link: '/education',
            icon: <TrainingCourse />,
          },
          {
            title: 'Мероприятия',
            text: 'Анонсы событий и записи вебинаров',
            link: '/events',
            icon: <Webinar />,
          },
          {
            title: 'Исследования Cloud.ru',
            text: 'Участвуйте в исследовании сервисов Cloud.ru',
            link: '/issledovanie-oblachnye-tehnologii',
            icon: <MagnifyingGlass />,
          },
        ],
      },
    ],
  },
];

function Menu({
  items,
  activeMenuItem,
  setActiveMenuItem,
  setIsModalMenuOpen,
  onClick,
  className = '',
}: {
  items: { id: string; title: string; link?: string; target?: string }[];
  activeMenuItem: string | null;
  setActiveMenuItem: React.Dispatch<React.SetStateAction<string | null>>;
  setIsModalMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onClick(): void;
  className?: string;
}) {
  const { clickAnalytics } = useAnalytics();

  const onMenuItemClickHandler = (id?: string) => {
    if (id && activeMenuItem !== id) {
      setIsModalMenuOpen(true);
      setActiveMenuItem(id);
    } else {
      setIsModalMenuOpen(false);
      setActiveMenuItem(null);
    }

    onClick();
  };

  return (
    <nav className={cn(s.menuWrapper, className)} data-qa="header_nav">
      <ul className={s.menu} data-qa="header_nav_list">
        {items.map(({ title, id, link, target }, index) => {
          if (link) {
            return (
              <Link
                data-qa="header_nav_link"
                className={cn(s.menuItem, 'header-mainMenu', 'link')}
                href={link}
                onClick={() => onMenuItemClickHandler()}
                key={id + index}
                id={`layout-header-link-${title}`}
                target={target}
                analyticsOptions={{
                  action: link,
                  clickZone: 'header',
                  clickElement: 'textclick',
                  clickContent: title,
                  uniqueId: `layout-header-link-${title}`,
                  transitionType: 'inside-link',
                }}
              >
                {title}
              </Link>
            );
          }
          return (
            <li
              key={index}
              className={cn(s.menuItem, 'header-mainMenu', {
                [s.menuItemActive]: activeMenuItem === id,
              })}
              id={`layout-header-item-${title}`}
              data-click="allclicks"
              onClick={() => {
                onMenuItemClickHandler(id);
                clickAnalytics({
                  action: 'click',
                  clickZone: 'header',
                  clickElement: 'textclick',
                  clickContent: title,
                  uniqueId: `layout-header-item-${title}`,
                  transitionType: 'inside-link',
                });
              }}
              data-qa="header_nav_item"
            >
              {title}
            </li>
          );
        })}
      </ul>
    </nav>
  );
}

interface HeaderProps {
  className?: string;
  showLayoutBanner?: boolean;
  withoutSearch?: boolean;
  withoutHeaderShadow?: boolean;
  staticHeader?: boolean;
  withConsultationButton?: boolean;
}

export function Header({
  className = '',
  showLayoutBanner = true,
  withoutSearch,
  withoutHeaderShadow,
  withConsultationButton = true,
  staticHeader = false,
}: HeaderProps) {
  const [globalSearchIsVisible, setGlobalSearchIsVisible] = useState(false);
  const [isModalConsultationOpen, setIsModalConsultationOpen] = useState(false);
  const [isModalMenuOpen, setIsModalMenuOpen] = useState(false);
  const [isInputVisible, setIsInputVibisle] = useState(false);
  const [activeMenuItem, setActiveMenuItem] = useState<string | null>(null);
  const [isBurgerOpen, setIsBurgerOpen] = useState(false);

  const {
    windowWidth,
    breakpoints: { MD },
  } = useWindowWidth();
  const {
    showHeader,
    showSubheader,
    setShowSubheader,
    headerPosition,
    headerHeight,
  } = useHeaderPosition(staticHeader, isModalMenuOpen, isBurgerOpen);

  const { sendDataToDataLayer } = useGtmDataLayer();

  const sendDatalayer = () => {
    const dataLayerToSend = {
      dataLayer: {
        event: 'custom_event',
        event_category: 'consultation LeadForm',
        event_action: 'formOpen',
        event_label: 'Header page',
      },
    };

    sendDataToDataLayer(dataLayerToSend);
  };

  const { locale } = useRouter();
  const lang = localeToLang(locale);

  const { data: solutions } = useSWR(`/content/v1/solutions`, (url: string) =>
    fetcher<{ solutions: SolutionsData[]; categories: SolutionCategory[] }>(
      url,
    ),
  );

  const { data: catalogDataRaw } = useSWR(
    `/content/v2/catalog?type=products&hidden=true`,
    (url: string) => fetcher<Catalog>(url),
  );

  const filteredProducts = catalogDataRaw?.products.filter(
    (product) =>
      product.productPlatforms.length > 1 ||
      Boolean(
        product.productPlatforms.find(
          (platform) => platform.slug === 'evolution',
        ),
      ),
  );

  const filteredCategories = catalogDataRaw?.categories.filter((category) =>
    filteredProducts?.some((product) =>
      product.categories?.some(
        (productCategory) => productCategory.id === category.id,
      ),
    ),
  );

  const popularCategoryId = filteredCategories?.find(
    (category) => category.slug === 'popular',
  );

  const oneCProductRaw = solutions?.solutions?.find(
    (solutionData) =>
      solutionData.slug === 'vse-programmy-1s-na-serverah-cloud',
  );

  const oneCProduct = popularCategoryId
    ? {
        ...oneCProductRaw,
        categories: [popularCategoryId],
        productPlatforms: [{}, {}],
        customLink: `solutions/${oneCProductRaw?.slug}`,
        markTitle:
          'Размещение  1С в высокопроизводительном и защищенном облаке',
      }
    : null;

  const catalogData = {
    categories: filteredCategories,
    products:
      filteredProducts && oneCProduct
        ? [...filteredProducts, oneCProduct]
        : filteredProducts,
  };

  const onConsultationClickHandler = () => {
    setActiveMenuItem(null);
    setIsBurgerOpen(false);
    setIsModalMenuOpen(false);
    setGlobalSearchIsVisible(false);
    setIsModalConsultationOpen((prev) => !prev);
    sendDatalayer();
  };

  const onBurgerClickHandler = () => {
    setIsModalMenuOpen(false);
    setActiveMenuItem(null);
    setIsModalConsultationOpen(false);
    setGlobalSearchIsVisible(false);
    setIsBurgerOpen((prev) => !prev);
  };

  const onClickForCloseMenuHandler = () => {
    setIsModalMenuOpen(false);
    setActiveMenuItem(null);
  };

  const onClickOnSameLinkHandler = () => {
    setIsBurgerOpen(false);
    setIsModalMenuOpen(false);
    setActiveMenuItem(null);
  };

  const onClickOnMenuItemHandler = () => {
    setGlobalSearchIsVisible(false);
    setIsModalConsultationOpen(false);
  };

  const onClickOnInputHandler = () => {
    setIsInputVibisle(!isInputVisible);
  };

  const onKeyDownOnInputHandler = (event: React.KeyboardEvent) => {
    if (event.key === 'Escape' || event.key === 'Esc') setIsInputVibisle(false);
  };

  const getContentForMenu = (id: string) => {
    let menuItem = MENU_ITEMS.find((item) => item.id === id);

    if (!menuItem) return MENU_ITEMS[0];

    if (id === '0' && catalogData) {
      menuItem = {
        ...menuItem,
        solutions: {
          categories: solutions?.categories ? solutions?.categories : [],
          items: solutions?.solutions ? solutions?.solutions : [],
        },
        catalogItems: catalogData as ProductsType,
      };
    }

    return menuItem;
  };

  const getContentForRobots = () => {
    const links: {
      title: string;
      link: string;
    }[] = [];

    MENU_ITEMS.map((menuRoot) => {
      if (menuRoot.type === 'list') {
        menuRoot.list?.forEach((menuInside) => {
          menuInside.items.forEach((innerLink) => {
            links.push({
              title: innerLink.title,
              link: innerLink.link,
            });
          });
        });
      }
    });

    return links.map(({ title, link }, index) => {
      return (
        <a key={`${index}_${link}`} href={link}>
          {title}
        </a>
      );
    });
  };

  const robotsLinks = getContentForRobots();

  const logInUrl = useFormatConsoleUrl(
    consoleRedirectUrl,
    'header',
    'В консоль',
  );

  const { customAnalytics } = useAnalytics();

  return (
    <>
      <header
        className={cn(s.root, className, {
          [s.shadow]:
            (headerPosition > 50 && !withoutHeaderShadow) ||
            globalSearchIsVisible,
          [s.withSubHeader]: showSubheader,
        })}
        style={{
          transform: `translateY(-${!showHeader ? headerHeight : 0}px)`,
        }}
        data-attr="layout-header"
      >
        {showLayoutBanner && (
          <SubHeader
            setShowSubheader={setShowSubheader}
            showSubheader={showSubheader}
          />
        )}
        {/* </div> */}
        <div className={s.headerTop}>
          <div className={s.wrapper}>
            <Link
              className={cn(s.logoWrapper, {
                [s.hidden]: windowWidth && windowWidth <= MD && isInputVisible,
              })}
              href="/"
              data-qa="header_logo_link"
              id="layout-header-logo-link"
              analyticsOptions={{
                action: '/',
                clickZone: 'header',
                clickElement: 'textclick',
                clickContent: 'Logo',
                uniqueId: 'layout-header-logo-link',
                transitionType: 'inside-link',
              }}
            >
              <Logo className={s.logo} />
            </Link>

            {!isInputVisible && (
              <Menu
                setIsModalMenuOpen={setIsModalMenuOpen}
                activeMenuItem={activeMenuItem}
                setActiveMenuItem={setActiveMenuItem}
                items={MENU_ITEMS}
                onClick={onClickOnMenuItemHandler}
                className={s.menuDesktop}
              />
            )}

            {lang === 'ru' && !withoutSearch && !isInputVisible && (
              <HeaderSearchInput
                onClick={onClickOnInputHandler}
                active={globalSearchIsVisible}
              />
            )}

            {isInputVisible && (
              <div className={s.searchWrapper}>
                <HeaderSearch
                  onCloseSearch={onClickOnInputHandler}
                  onKeyBoardCloseSearch={onKeyDownOnInputHandler}
                />
              </div>
            )}

            {windowWidth &&
              windowWidth > MD &&
              withConsultationButton &&
              !isInputVisible && (
                <Button
                  variant={Variant.Tertiary}
                  className={cn(s.consultation)}
                  onClick={onConsultationClickHandler}
                  data-qa="header_button_consultation"
                  analyticsOptions={{
                    action: 'click',
                    clickZone: 'header',
                    clickElement: 'button',
                    clickContent: 'Связаться с нами',
                    uniqueId: 'header-consultation',
                    transitionType: 'inside-link',
                  }}
                >
                  Связаться с нами
                </Button>
              )}
            {((windowWidth && windowWidth > MD) || !isInputVisible) && (
              <Button
                className={s.button}
                link={logInUrl}
                onClick={() => customAnalytics('Btn-head-lk-enter', [''])}
                analyticsOptions={{
                  action: logInUrl,
                  clickZone: 'header',
                  clickElement: 'button',
                  clickContent: 'В консоль',
                  uniqueId: 'header-log-in',
                  transitionType: 'inside-link',
                }}
                data-abt="login"
              >
                В консоль
              </Button>
            )}
            {windowWidth && windowWidth <= MD && !isInputVisible && (
              <BurgerButton
                onClick={onBurgerClickHandler}
                isBurgerOpen={isBurgerOpen}
              />
            )}
          </div>

          {isModalConsultationOpen && (
            <Form
              productTitle="header"
              title="Связаться с нашим специалистом"
              inputPath="v2/order"
              additionalFields={{ subsegment: 'consultation' }}
              formVariant="modal"
              allFormsSentFormType="consultation"
              consultationFormType="header"
              allFormsSentIsPopup
              tryFormType="consultation"
              modalClose={onConsultationClickHandler}
              analyticsFieldFill={{
                formType: 'popup',
                popupPlace: 'header',
                buttonId: 'header-consultation',
              }}
            />
          )}

          <div className={s.hidden}>{robotsLinks}</div>

          {isModalMenuOpen && !!activeMenuItem && (
            <ModalMenu
              onClickOnSameLink={onClickOnSameLinkHandler}
              onClickForClose={onClickForCloseMenuHandler}
              content={getContentForMenu(activeMenuItem)}
              showSubheader={showSubheader}
              isBurgerOpen={isBurgerOpen}
              onBurgerClickHandler={onBurgerClickHandler}
              headerHeight={headerHeight}
            />
          )}

          {isBurgerOpen && windowWidth && windowWidth <= MD && (
            <ModalBurger
              onConsultationClickHandler={onConsultationClickHandler}
              withConsultationButton={withConsultationButton}
              onClickForClose={onBurgerClickHandler}
              menuItems={MENU_ITEMS}
              setIsModalMenuOpen={setIsModalMenuOpen}
              isBurgerOpen={isBurgerOpen}
              setActiveMenuItem={setActiveMenuItem}
              activeMenuItem={activeMenuItem}
              showSubheader={showSubheader}
              headerHeight={headerHeight}
            />
          )}
        </div>
      </header>
    </>
  );
}
