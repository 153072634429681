import s from './MessageErrorOnFormSubmit.module.scss';

interface MessageErrorOnFormSubmitProps {
  text: string;
}

export function MessageErrorOnFormSubmit({
  text,
}: MessageErrorOnFormSubmitProps): JSX.Element {
  return (
    <div className={s.root}>
      <span className={s.title}>{text}</span>
    </div>
  );
}
