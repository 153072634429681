import cn from 'classnames';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { useAnalytics } from 'src/hooks/useAnalytics';
import { Button } from 'src/uikit/Button';

import s from './HeaderSearch.module.scss';

function CloseIcon({ onClick }: { onClick?: () => void }) {
  return (
    <svg
      className={s.closeIcon}
      width="20"
      height="20"
      onClick={onClick}
      viewBox="0 0 20 20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_9199_61990)">
        <rect
          x="4.10742"
          y="14.7148"
          width="15"
          height="1.66667"
          rx="0.833333"
          transform="rotate(-45 4.10742 14.7148)"
        />
        <rect
          x="14.7141"
          y="15.8926"
          width="15"
          height="1.66667"
          rx="0.833333"
          transform="rotate(-135 14.7141 15.8926)"
        />
      </g>
      <defs>
        <clipPath id="clip0_9199_61990">
          <rect width="20" height="20" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function HeaderSearch({
  onCloseSearch,
  onKeyBoardCloseSearch,
}: {
  onCloseSearch(): void;
  onKeyBoardCloseSearch(event: React.KeyboardEvent): void;
}) {
  const { funnelFormInput, searchAnalytics } = useAnalytics();

  const [inputValue, setInputValue] = useState('');

  const router = useRouter();

  const handleSubmitButton = (event: React.KeyboardEvent) => {
    if (inputValue.length >= 2) {
      if (event.key === 'Enter') {
        handleRouterPush(inputValue);
      }
    }
  };

  const handleRouterPush = (input: string) => {
    const encodedValue = encodeURIComponent(input);
    searchAnalytics('header', input);
    router.push(`/search?query=${encodedValue}`);
  };

  const clearBtnClick = () => {
    setInputValue('');
  };

  return (
    <div
      onKeyDown={(event: React.KeyboardEvent) => onKeyBoardCloseSearch(event)}
      tabIndex={1}
    >
      <div>
        <div className={s.fieldWrapper}>
          <input
            className={s.field}
            placeholder="Поиск"
            value={inputValue ?? ''}
            onChange={(event) => setInputValue(event.target.value)}
            onClick={funnelFormInput}
            onKeyDown={handleSubmitButton}
            autoFocus
          />
          {inputValue.length > 1 ? (
            <Button
              className={cn(s.sendingButton, {
                [s.withInputValue]: inputValue.length > 1,
              })}
              variant={Button.variants.Tertiary}
              onClick={() => handleRouterPush(inputValue)}
              analyticsOptions={{
                action: 'click',
                clickZone: 'body',
                clickElement: 'button',
                clickContent: 'Найти',
                uniqueId: `search-header-button-send-query`,
                transitionType: 'inside-link',
              }}
            >
              Найти
            </Button>
          ) : (
            <Button
              className={cn(s.sendingButton, {
                [s.withInputValue]: inputValue.length > 1,
              })}
              variant={Button.variants.Tertiary}
              onClick={onCloseSearch}
              type="submit"
              data-qa="next_step_button"
              analyticsOptions={{
                action: 'click',
                clickZone: 'body',
                clickElement: 'button',
                clickContent: 'Закрыть',
                uniqueId: `search-page-button-close-input`,
                transitionType: 'inside-link',
              }}
            >
              Закрыть
            </Button>
          )}
          {inputValue.length > 1 && <CloseIcon onClick={clearBtnClick} />}
        </div>
      </div>
    </div>
  );
}
