import cn from 'classnames';
import React, { ChangeEvent, ReactNode, useId } from 'react';
import { FieldValues, UseFormRegister } from 'react-hook-form';
import { useAnalytics } from 'src/hooks/useAnalytics';
import { valSchema } from 'src/utils/valSchema';

import s from './CheckboxGroup.module.scss';

interface CheckboxGroupOption {
  value: string;
  label: string | ReactNode;
}

type CheckboxGroupProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> &
  (CheckboxGroupNotRequired | CheckboxGroupRequired);

interface CheckboxGroupBase {
  label: string;
  name: string;
  options: CheckboxGroupOption[];
  errors: any;
  register: UseFormRegister<FieldValues>;
  variant?: 'light' | 'dark';
  groupClassName?: string;
  onCustomChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}

interface CheckboxGroupNotRequired extends CheckboxGroupBase {
  required: false;
  requiredText?: undefined;
}

interface CheckboxGroupRequired extends CheckboxGroupBase {
  required: true;
  requiredText: string;
}

function CheckIcon() {
  return (
    <svg
      className={s.icon}
      width="13"
      height="10"
      viewBox="0 0 13 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9392 1.031C11.3512 0.442996 10.4692 0.442996 9.88116 1.031L5.03014 5.88202L3.11913 3.97101C2.53113 3.38301 1.64912 3.38301 1.06112 3.97101C0.473116 4.55902 0.473116 5.44102 1.06112 6.02902L4.00113 8.96904C4.29514 9.26304 4.58914 9.41004 5.03014 9.41004C5.47114 9.41004 5.76514 9.26304 6.05914 8.96904L11.9392 3.08901C12.5272 2.50101 12.5272 1.619 11.9392 1.031Z"
        fill="#212830"
      />
    </svg>
  );
}

export function CheckboxGroup({
  label,
  name,
  options,
  errors,
  className = '',
  register,
  requiredText,
  required,
  variant = 'light',
  groupClassName = '',
  onCustomChange,
  ...rest
}: CheckboxGroupProps) {
  const { funnelFormInput } = useAnalytics();
  const id = useId();

  const { onChange, ...restOptions } = {
    ...register(name, {
      required: required ? valSchema.default?.required : false,
    }),
  };

  return (
    <div
      className={cn(s.root, className, {
        [s.error]: errors && errors[name],
        [s.dark]: variant === 'dark',
      })}
    >
      <div
        className={cn(s.header, {
          [s.resetMargin]: !label && !requiredText,
        })}
      >
        <span className={s.title}>{label}</span>
        {requiredText && <span className={s.reqText}>{requiredText}</span>}
      </div>
      <div className={cn(s.group, groupClassName)}>
        {options.map((content, index) => (
          <div className={s.item} key={index}>
            <input
              data-qa="checkbox"
              data-click="funnel-form-input"
              className={s.input}
              value={content.value}
              id={name + index + id}
              type="checkbox"
              {...rest}
              {...restOptions}
              onChange={(event) => {
                onChange(event);
                onCustomChange && onCustomChange(event);
                funnelFormInput();
              }}
            />
            <div className={s.wrapper}>
              <label className={s.label} htmlFor={name + index + id}>
                <CheckIcon />
                {content.label}
              </label>
            </div>
          </div>
        ))}
      </div>
      {errors && errors[name] && (
        <span className={s.errorText}>{errors[name].message}</span>
      )}
    </div>
  );
}
