import { Button } from 'src/uikit/Button';

interface MessageFormSubmittedProps {
  title: string;
  text?: string;
  buttonText: string;
  onClickOnReturnButton: any;
  variant?: string;
  className?: string;
}

export function MessageFormSubmitted({
  title,
  text,
  buttonText,
  onClickOnReturnButton,
  variant,
  className,
}: MessageFormSubmittedProps) {
  let styles = 'c-form-message-form-submitted';

  if (variant === 'white')
    styles =
      'c-form-message-form-submitted c-form-message-form-submitted_white';
  if (variant === 'dark')
    styles = 'c-form-message-form-submitted c-form-message-form-submitted_dark';

  if (className) styles += ` ${className}`;

  return (
    <div className={styles}>
      <span className="c-form-message-form-submitted-title">{title}</span>
      {text && (
        <span className="c-form-message-form-submitted-text">{text}</span>
      )}
      <Button
        onClick={onClickOnReturnButton}
        className="button"
        analyticsOptions={{
          action: 'click',
          clickZone: 'body',
          clickElement: 'button',
          clickContent: buttonText,
          uniqueId: `form-in-page-button-${title}`,
          transitionType: 'inside-link',
        }}
      >
        {buttonText}
      </Button>
    </div>
  );
}
