import cn from 'classnames';
import { Fragment } from 'react';
import { useWindowWidth } from 'src/hooks/useWindowWidth';

import { ListGridType, ListType } from '../../types';
import { LinkCard } from '../LinkCard';
import { OfferCard } from '../OfferCard';
import s from './List.module.scss';

export function List({
  list,
  gridColumns = 'three',
  onClickForClose,
}: {
  list: ListType;
  onClickForClose(): void;
  gridColumns?: ListGridType;
}) {
  const {
    windowWidth,
    breakpoints: { MD },
  } = useWindowWidth();

  return (
    <div className={s.root}>
      {windowWidth && windowWidth > MD ? (
        <div className={cn(s.grid, s[gridColumns])}>
          {list.map(({ title, items }, index) => (
            <Fragment key={`${title}_${index}`}>
              {title && (
                <h2 className={cn(s.title, s[gridColumns])}>{title}</h2>
              )}
              {items.map(
                (
                  {
                    title,
                    text,
                    link,
                    icon,
                    tags,
                    isButton,
                    target,
                    theme,
                    type,
                  },
                  index,
                ) => {
                  if (type === 'offers') {
                    return (
                      <OfferCard
                        key={index}
                        title={title}
                        link={link}
                        image={icon}
                        onClickForClose={onClickForClose}
                        target={target}
                        theme={theme}
                      />
                    );
                  }

                  return (
                    <LinkCard
                      key={index}
                      title={title}
                      text={text}
                      link={link}
                      image={icon}
                      tags={tags}
                      isButton={isButton}
                      onClickForClose={onClickForClose}
                      target={target}
                    />
                  );
                },
              )}
            </Fragment>
          ))}
        </div>
      ) : (
        <>
          {list.map(({ title, items }, index) => {
            return (
              <div className={s.col} key={index}>
                {title && <h2 className={s.title}>{title}</h2>}
                <div className={s.items}>
                  {items.map(
                    (
                      {
                        title,
                        text,
                        link,
                        isButton,
                        target,
                        type,
                        theme,
                        icon,
                      },
                      index,
                    ) => {
                      if (type === 'offers') {
                        return (
                          <OfferCard
                            key={index}
                            title={title}
                            link={link}
                            image={icon}
                            onClickForClose={onClickForClose}
                            target={target}
                            theme={theme}
                          />
                        );
                      }
                      return (
                        <LinkCard
                          onClickForClose={onClickForClose}
                          title={title}
                          text={text}
                          link={link}
                          isButton={isButton}
                          target={target}
                          key={index}
                        />
                      );
                    },
                  )}
                </div>
              </div>
            );
          })}
        </>
      )}
    </div>
  );
}
