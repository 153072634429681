import React from 'react';

export interface LogoProps {
  className?: string;
}

export function Logo({ className = '' }: LogoProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 710.71 131.45"
      width="113"
      height="22"
      className={className}
    >
      <g>
        <path
          fill="#26d07c"
          d="m63.54,73.4h54.86v34.94l-54.87,23.1v-58.05Zm54.86-8.09V30.37L63.54,7.26v58.02s54.86.03,54.86.03ZM0,30.37v77.98l54.87,23.1V7.26L0,30.37Z"
        />
        <path
          fill="#222"
          d="m196.14,28.63c-26.03,0-40.05,14.75-40.05,40.77s14.02,40.77,39.33,40.77c16.63,0,30.07-8.39,35.42-25.45h-17.35c-3.33,5.78-8.1,9.4-18.07,9.4-14.89,0-20.82-7.95-20.82-24.58v-.43c0-16.63,5.93-24.43,21.54-24.43,9.25,0,14.02,3.18,17.35,8.68h17.35c-5.35-16.48-18.07-24.72-34.7-24.72Zm182.18,48.15c0,11.57,5.78,17.35,17.35,17.35s17.35-5.78,17.35-17.35V30.36h18.22v78.08h-17.06v-11.57c-4.77,8.24-12.58,13.3-25.59,13.3-18.51,0-28.48-10.12-28.48-30.36V30.36h18.22v46.41Zm279.48,0c0,11.57,5.78,17.35,17.35,17.35s17.35-5.78,17.35-17.35V30.36h18.22v78.08h-17.06v-11.57c-4.77,8.24-12.58,13.3-25.59,13.3-18.51,0-28.48-10.12-28.48-30.36V30.36h18.22v46.41Zm-174.51,17.35c15.62,0,21.69-7.95,21.69-24.58v-.43c0-16.63-6.07-24.29-21.69-24.29s-21.69,7.66-21.69,24.29v.43c0,16.63,6.07,24.58,21.69,24.58ZM522.61,0v108.44h-17.35v-11.57c-4.92,7.81-12.43,13.3-25.3,13.3-24.58,0-36.87-17.06-36.87-39.62,0-24.72,12.29-41.78,36.87-41.78,10.7,0,20.96,5.21,24.43,12.15V0h18.22ZM237.92,108.44h18.22V0h-18.22v108.44Zm70.27,1.74c-24.87,0-40.48-15.47-40.48-40.77s15.62-40.77,40.48-40.77,40.48,15.47,40.48,40.77-15.62,40.77-40.48,40.77Zm-21.98-40.63c0,16.63,6.07,24.58,21.98,24.58s21.98-7.95,21.98-24.58v-.43c0-16.63-6.07-24.43-21.98-24.43s-21.98,7.81-21.98,24.43v.43Zm335.58-39.18h8.68v17.35h-13.01c-11.57,0-15.47,5.35-15.47,14.02v46.7h-18.22V30.22h17.35v14.46c3.47-9.98,10.41-14.31,20.68-14.31Zm-68.68,79.81c7.95,0,13.01-4.77,13.01-12.87s-5.06-12.87-13.01-12.87-13.01,4.77-13.01,12.87,5.06,12.87,13.01,12.87Z"
        />
      </g>
    </svg>
  );
}
