import cn from 'classnames';
import { ArrowOut } from 'src/uikit/icons/ContentIcons';
import { Link } from 'src/uikit/Link';

import s from './AdditionalLinks.module.scss';

type AdditionalLinksProps = {
  title: string;
  slug: string;
};

export function AdditionalLinks({
  links,
  className = '',
}: {
  links: AdditionalLinksProps[];
  className?: string;
}) {
  return (
    <div className={cn(s.additionalMenuLinks, className)}>
      {links.map(({ title, slug }, index) => {
        return (
          <Link
            key={`${title}_${index}`}
            className={cn(s.additionalLink, 'link')}
            href={slug}
            analyticsOptions={{
              action: slug,
              clickZone: 'header',
              clickElement: 'textclick',
              clickContent: title,
              uniqueId: `nav-additional-link-${title}`,
              transitionType: 'inside-link',
            }}
          >
            <span>{title}</span>
            <ArrowOut className={s.additionalLinkIcon} />
          </Link>
        );
      })}
    </div>
  );
}
