import cn from 'classnames';
import { useAnalytics } from 'src/hooks/useAnalytics';
import { useWindowWidth } from 'src/hooks/useWindowWidth';
import { SearchIcon } from 'src/uikit/icons';

import s from './HeaderSearchInput.module.scss';

export function HeaderSearchInput({
  onClick,
  active,
}: {
  onClick(): void;
  active?: boolean;
}) {
  const { clickAnalytics } = useAnalytics();

  const {
    windowWidth,
    breakpoints: { MD },
  } = useWindowWidth();

  return (
    <div
      className={cn(s.root, {
        [s.active]: !!active,
        [s.adaptive]: windowWidth && windowWidth < MD,
      })}
      onClick={() => {
        onClick();
        clickAnalytics({
          action: 'click',
          clickZone: 'header',
          clickElement: 'textclick',
          clickContent: 'Поиск',
          uniqueId: `header-search-input-click`,
          transitionType: 'inside-link',
        });
      }}
      data-qa="header_search"
    >
      <SearchIcon className={s.icon} />
    </div>
  );
}
