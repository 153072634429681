import React from 'react';
import { IconProps } from 'src/types';

export function Youtube({ className }: IconProps): JSX.Element {
  return (
    <svg
      width="6"
      height="8"
      viewBox="0 0 6 8"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M6 4L1.50571e-07 7.4641L4.53412e-07 0.535898L6 4Z" />
    </svg>
  );
}
