import cn from 'classnames';
import { ChangeEvent, useState } from 'react';
import { FieldValues, UseFormRegister } from 'react-hook-form';
import ReactInputMask from 'react-input-mask';
import { QuestionIcon } from 'src/components/Icons/QuestionIcon';
import { useAnalytics } from 'src/hooks/useAnalytics';
import { valSchema } from 'src/utils/valSchema';

import s from './Input.module.scss';

type BaseInputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> &
  InputVariant;

type ValidationType =
  | 'name'
  | 'address'
  | 'inn'
  | 'phone'
  | 'number'
  | 'email'
  | 'company'
  | 'surname'
  | 'corpEmail'
  | 'position'
  | 'product'
  | 'site'
  | 'default';

interface InputBase {
  label?: string;
  name: string;
  errors: any;
  placeholder?: string;
  hint?: { label: string; text: string };
  register: UseFormRegister<FieldValues>;
  disabled?: boolean;
  required: boolean;
  validationType: ValidationType;
  className?: string;
  theme?: 'purple' | 'base';
  inputClassName?: string;
  errorClassName?: string;
  requiredText?: string;
  onChangeCustom?: (event: ChangeEvent<HTMLInputElement>) => void;
  onBlurCustom?: (event: ChangeEvent<HTMLInputElement>) => void;
  inputType?: 'inn' | 'phone';
}

interface PhoneInput extends InputBase {
  inputType: 'phone';
}

interface InnInput extends InputBase {
  inputType: 'inn';
}

interface TextInput extends InputBase {
  inputType?: undefined;
}

type InputVariant = PhoneInput | TextInput | InnInput;

function SuccessIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={s.iconSuccess}
    >
      <g clipPath="url(#clip0_17835_2466)">
        <path
          d="M9.00012 16.1998L5.50012 12.6998C5.11012 12.3098 4.49012 12.3098 4.10012 12.6998C3.71012 13.0898 3.71012 13.7098 4.10012 14.0998L8.29012 18.2898C8.68012 18.6798 9.31012 18.6798 9.70012 18.2898L20.3001 7.69982C20.6901 7.30982 20.6901 6.68982 20.3001 6.29982C19.9101 5.90982 19.2901 5.90982 18.9001 6.29982L9.00012 16.1998Z"
          fill="#07E897"
        />
      </g>
    </svg>
  );
}

const getSchema = (
  validationType: ValidationType,
  required: boolean,
  disabled?: boolean,
) => {
  const schema = valSchema[validationType];
  return {
    ...schema,
    required: !disabled && required ? schema?.required : false,
  };
};

export function Input({
  label,
  name,
  errors,
  placeholder,
  hint,
  register,
  required,
  disabled,
  requiredText,
  inputType,
  validationType,
  className = '',
  inputClassName = '',
  errorClassName = '',
  onChangeCustom,
  theme = 'base',
  onBlurCustom,
  ...rest
}: BaseInputProps) {
  const { funnelFormInput } = useAnalytics();

  const { onChange, onBlur, ...restOptions } = register(name);
  const [phoneValue, setPhoneValue] = useState('+7 (___) ___-__-__');

  const onChangeInputHandler = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event);
    onChangeCustom && onChangeCustom(event);
  };

  const onBlurInputHandler = (event: ChangeEvent<HTMLInputElement>) => {
    onBlur(event);
    onBlurCustom && onBlurCustom(event);
  };

  const phoneRegExp = /^(\s*)?(\+)?([- ():=+]?\d[- ():=+]?){11,14}(\s*)?$/;

  return (
    <div
      className={cn(s.root, className, errorClassName, {
        [s.error]: errors && errors[name],
        [s.purple]: theme === 'purple',
      })}
      onClick={() => funnelFormInput()}
    >
      <div className={cn(s.top, { [s.topVisible]: hint || requiredText })}>
        {hint && (
          <div className={s.hint}>
            <span className={s.hintLabel}>{hint.label}</span>
            <QuestionIcon />
            <div className={s.hintText}>{hint.text}</div>
          </div>
        )}
        {requiredText && <span className={s.reqText}>{requiredText}</span>}
      </div>
      <div className={s.inner}>
        <div className={s.wrapper}>
          {inputType === 'phone' && (
            <ReactInputMask
              className={cn(s.input, s.inputPhone, {
                [s.phoneCorrect]:
                  phoneValue !== '+7 (___) ___-__-__' &&
                  phoneRegExp.test(phoneValue),
              })}
              alwaysShowMask
              data-click="funnel-form-input"
              defaultValue="+7 (___) ___-__-__"
              mask="+7 (999) 999-99-99"
              {...register(name, getSchema(validationType, required, disabled))}
              onChange={(event) => {
                onChangeInputHandler(event);
                setPhoneValue(event.target.value);
              }}
              onBlur={(event) => {
                onBlurInputHandler(event);
              }}
            />
          )}
          {!inputType && (
            <input
              className={cn(s.input, inputClassName)}
              id={name}
              placeholder={placeholder}
              {...register(name, getSchema(validationType, required, disabled))}
              disabled={disabled}
              data-click="funnel-form-input"
              {...rest}
              {...restOptions}
              onChange={(event) => onChangeInputHandler(event)}
              onBlur={(event) => onBlurInputHandler(event)}
            />
          )}
          {inputType === 'inn' && (
            <input
              className={cn(s.input, inputClassName)}
              id={name}
              data-click="funnel-form-input"
              type="number"
              placeholder={placeholder}
              onWheel={(e) => (e.target as HTMLElement).blur()}
              onScroll={undefined}
              {...register(name, getSchema(validationType, required, disabled))}
              disabled={disabled}
              {...rest}
              {...restOptions}
              onChange={(event) => onChangeInputHandler(event)}
              onBlur={(event) => onBlurInputHandler(event)}
            />
          )}
          <label className={s.name} htmlFor={name}>
            {label}
          </label>
          <SuccessIcon />
        </div>
        {errors && errors[name] && (
          <span className={s.errorText}>
            {errors[name].message.length > 0
              ? errors[name].message
              : 'Обязательное поле'}
          </span>
        )}
      </div>
    </div>
  );
}
