const Mlspace = ({ className }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="12"
      viewBox="0 0 19 12"
      fill="none"
      className={className}
    >
      <path
        d="M9.38322 11.5577V1.3167V0H7.87961V0.243833C7.8311 0.536432 7.5886 0.731499 7.34607 0.731499H6.13348L4.72689 4.58405L3.3203 0.780267H2.15621C1.81668 0.780267 1.57417 0.487667 1.57417 0.1463V0H0.0705566V11.5577H1.57417V1.3167H1.76818C1.96219 1.3167 2.0592 1.36547 2.15621 1.51177L4.19336 6.97363H5.35745L7.34607 1.51177C7.44308 1.36547 7.4916 1.3167 7.7341 1.3167H7.87961V11.5577H9.38322Z"
        fill="currentColor"
      />
      <path
        d="M17.0743 9.50949C17.0743 9.8021 16.8318 10.0459 16.4923 10.0459H13.5821V1.51177H15.2312V0H10.3809V1.51177H12.03V10.0459H10.3809V11.5577H17.8019V11.3626C17.8019 11.07 18.0444 10.7774 18.3839 10.7774H18.5294V6.68102H17.0258V9.50949H17.0743Z"
        fill="currentColor"
      />
    </svg>
  );
};

export { Mlspace };
