import s from './ShadowInput.module.scss';

interface ShadowInputProps {
  register: any;
}

export function ShadowInput({ register }: ShadowInputProps) {
  return (
    <input
      className={s.root}
      name="shadowInput"
      type="text"
      data-qa="shadowInput"
      {...register('shadowInput')}
    />
  );
}
