import cn from 'classnames';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { FieldValues, RegisterOptions, UseFormRegister } from 'react-hook-form';
import { useAnalytics } from 'src/hooks/useAnalytics';
import { useWindowWidth } from 'src/hooks/useWindowWidth';
import { valSchema } from 'src/utils/valSchema';

import s from './TextArea.module.scss';

type BaseTextAreaProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLTextAreaElement>,
  HTMLTextAreaElement
> &
  TextAreaProps;

interface TextAreaProps {
  label?: string;
  name: string;
  errors: any;
  placeholder?: string;
  hint?: { label: string; text: string };
  register: UseFormRegister<FieldValues>;
  registerOptions?: RegisterOptions;
  disabled?: boolean;
  required: boolean;
  theme?: 'base' | 'purple';
  requiredText?: string;
  minHeight?: string;
  withLength?: boolean;
  textareaClassName?: string;
  onBlurCustom?: (event: ChangeEvent<HTMLTextAreaElement>) => void;
}

export function TextArea({
  label,
  name,
  errors,
  placeholder,
  register,
  required,
  disabled,
  requiredText,
  maxLength,
  theme,
  minHeight = '164',
  withLength,
  textareaClassName = '',
  onBlurCustom,
  ...rest
}: BaseTextAreaProps) {
  const [length, setLength] = useState(0);
  const labelRef = useRef<HTMLLabelElement | null>(null);
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);
  const onSetHeight = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { target } = e;
    target.style.height = target.value === '' ? `${minHeight}px` : 'auto';
    target.setAttribute('rows', '1');
    target.style.height = `${target.scrollHeight}px`;
  };
  const { ref, onChange, onBlur, ...restOptions } = register(name, {
    required: required ? valSchema.comment?.required : false,
  });
  const { windowWidth } = useWindowWidth();
  const { funnelFormInput } = useAnalytics();

  useEffect(() => {
    resize();
  }, [windowWidth]);

  const resize = () => {
    if (textAreaRef.current && labelRef.current) {
      const height = labelRef.current.getBoundingClientRect().height;
      textAreaRef.current.style.paddingTop = minHeight
        ? `${height - 6}px`
        : `${height + 5}px`;
    }
  };

  const onChangeHandler = (event: ChangeEvent<HTMLTextAreaElement>) => {
    resize();
    onChange(event);
    setLength(event.target.value.length);
  };

  return (
    <div
      className={cn(s.root, {
        [s.error]: errors && errors[name],
        [s.purple]: theme === 'purple',
      })}
      onClick={() => funnelFormInput()}
    >
      <div className={cn(s.top, { [s.topVisible]: requiredText })}>
        {requiredText && <span className={s.reqText}>{requiredText}</span>}
      </div>
      <div className={s.inner}>
        <div className={s.wrapper}>
          <textarea
            id={name}
            className={cn(s.textarea, textareaClassName)}
            placeholder={placeholder}
            ref={(event) => {
              textAreaRef.current = event;
              ref(event);
            }}
            data-click="funnel-form-input"
            disabled={disabled}
            maxLength={maxLength ?? 300}
            onInput={onSetHeight}
            onChange={(event) => onChangeHandler(event)}
            onFocus={resize}
            {...rest}
            {...restOptions}
            onBlur={(event) => {
              onBlur(event);
              onBlurCustom && onBlurCustom(event);
            }}
          />
          <label className={s.name} htmlFor={name}>
            <span className={s.hiddenName} ref={labelRef}>
              {label}
            </span>
            {label}
          </label>
        </div>
        <div className={s.bottom}>
          {errors && errors[name] && (
            <span className={s.errorText}>{errors[name].message}</span>
          )}
          {withLength && (
            <span className={s.length}>
              {length}/{maxLength ?? 300}
            </span>
          )}
        </div>
      </div>
    </div>
  );
}
