import 'react-loading-skeleton/dist/skeleton.css';

import cn from 'classnames';
import { useRouter } from 'next/router';
import React, { SetStateAction, useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useAnalytics } from 'src/hooks/useAnalytics';
import { useGtmDataLayer } from 'src/hooks/useGtmDataLayer';
import { useProductsSearch } from 'src/hooks/useProductsSearch';
import { useWindowWidth } from 'src/hooks/useWindowWidth';
import {
  CatalogProduct,
  ProductCategory,
  ProductPlatform,
  SolutionCategory,
} from 'src/types/backendContent';
import { SearchInput } from 'src/uikit/SearchInput';

import { ProductsSectionProps } from '../../types';
import { AdditionalLinks } from '../AdditionalLinks';
import { Card } from '../Card';
import { Filter } from '../Filter';
import s from './Products.module.scss';

export const PLATFORMS_CATEGORY = [
  {
    id: 'platforms',
    title: 'Платформы',
    slug: 'platforms',
    description:
      'более 80+ Iaas, Pass, ML сервисов на&nbsp<span class="categoryDescription">4 платформах</span>',
    src: 'https://cdn.cloud.ru/backend/layout/header/platforms_icon.svg',
  },
];

export const ADDITIONAL_LINKS = [
  {
    title: 'Marketplace',
    slug: '/marketplace',
  },

  {
    title: 'Реферальная программа',
    slug: '/partners/referral ',
  },

  {
    title: 'Все продукты',
    slug: '/products',
  },
];

interface ProductsFilterProps {
  value: string;
  onValueChange: (value: string) => void;
}

function ProductsFilter({ value, onValueChange }: ProductsFilterProps) {
  const placeholder = 'Поиск по названию';
  return (
    <div className={s.filter}>
      <SearchInput
        className={s.searchInput}
        wrapperClassName={s.searchInputWrapper}
        value={value}
        placeholder={placeholder}
        variant="small"
        onValueChange={onValueChange}
        clickZone="header"
      />
    </div>
  );
}

function Menu({
  filterValue,
  activeCategory,
  setFilterValue,
  setActiveCategory,
  productsCategories,
}: {
  filterValue: string;
  activeCategory: ProductCategory | SolutionCategory;
  productsCategories: (ProductCategory & { src?: string })[];
  setFilterValue: React.Dispatch<SetStateAction<string>>;
  setActiveCategory: React.Dispatch<
    SetStateAction<ProductCategory | SolutionCategory>
  >;
}) {
  const { sendDataToDataLayer } = useGtmDataLayer();
  const { clickAnalytics } = useAnalytics();

  const onMenuItemClick = (title: string) => {
    const dataLayer = {
      event: 'custom_event',
      event_category: 'click',
      event_action: 'click_products_menu',
      event_label: title,
    };

    sendDataToDataLayer(dataLayer);
  };

  const handleClickItem = async (
    title: string,
    category: SolutionCategory | ProductCategory,
  ) => {
    onMenuItemClick(title);
    setActiveCategory(category);
    clickAnalytics({
      action: '/',
      clickZone: 'header',
      clickElement: 'textclick',
      clickContent: title,
      uniqueId: `layout-header-product-category-${title}`,
      transitionType: 'inside-link',
    });
  };

  return (
    <div className={s.menu}>
      <ProductsFilter
        onValueChange={(value) => setFilterValue(value)}
        value={filterValue}
      />

      <div className={s.scrollbarMenu}>
        <div className={cn(s.menuSection, s.last)}>
          {productsCategories.map((el) => (
            <div
              className={cn(s.menuItem, {
                [s.menuItemActive]: activeCategory.id === el.id,
              })}
              key={el.id}
              id={`layout-header-product-category-${el.title}`}
              data-click="allclicks"
              onClick={() => handleClickItem(el.title, el)}
            >
              <div dangerouslySetInnerHTML={{ __html: el.title }} />
              {el.src && (
                <img
                  className={s.menuItemImg}
                  src={el.src}
                  alt="category_icon"
                />
              )}
            </div>
          ))}
        </div>
        <AdditionalLinks links={ADDITIONAL_LINKS} />
      </div>
    </div>
  );
}

export function ProductsSkeleton() {
  const {
    windowWidth,
    breakpoints: { MD },
  } = useWindowWidth();

  return (
    <div className={s.root}>
      <div className={s.content}>
        {windowWidth && windowWidth <= MD && (
          <ProductsFilter onValueChange={() => {}} value="" />
        )}

        <div className={s.menu}>
          <ProductsFilter onValueChange={() => {}} value="" />
          <div className={s.menuSection}>
            {Array.from(Array(2).keys()).map((_, index) => (
              <div className={s.skeletonMenuItem} key={index}>
                <Skeleton key={index} />
              </div>
            ))}
          </div>
          <div className={cn(s.menuSection, s.last)}>
            {Array.from(Array(6).keys()).map((_, index) => (
              <div className={s.skeletonMenuItem} key={index}>
                <Skeleton key={index} />
              </div>
            ))}
          </div>
        </div>

        <div className={s.items}>
          <div className={cn(s.itemsWrapper, 'header-block')}>
            <div className={cn(s.block)}>
              <h2
                className={s.title}
                dangerouslySetInnerHTML={{
                  __html: 'Популярное',
                }}
              />
              <div className={cn(s.blockGrid)}>
                {Array.from(Array(6).keys()).map((_, index) => (
                  <div key={index} className={s.skeletonCard}>
                    <Skeleton className={s.skeletonImg} />
                    <div>
                      <Skeleton className={s.skeletonTitle} />
                      <Skeleton className={s.skeletonDesc} />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export function Products({
  products,
  solutions,
  onClickForClose,
}: ProductsSectionProps) {
  const [activeCategory, setActiveCategory] = useState<
    ProductCategory | SolutionCategory
  >({ id: -1, title: '', slug: '' });
  const [filterValue, setFilterValue] = useState('');
  const { pathname } = useRouter();

  const { sendDataToDataLayer } = useGtmDataLayer();
  const {
    windowWidth,
    breakpoints: { MD },
  } = useWindowWidth();

  const sendDatalayer = (label: string) => {
    const dataLayerToSend = {
      dataLayer: {
        event: 'custom_event',
        event_category: 'click',
        event_action: 'click_header_product_card',
        event_label: label,
      },
    };

    sendDataToDataLayer(dataLayerToSend);
  };
  const { searchAnalytics } = useAnalytics();

  useEffect(() => {
    if (filterValue !== '' && activeCategory.id !== -1) {
      setActiveCategory({ id: -1, title: '', slug: '' });
    }
  }, [filterValue]);

  useEffect(() => {
    if (filterValue !== '' && activeCategory.id !== -1) {
      setFilterValue('');
    }
  }, [activeCategory]);

  useEffect(() => {
    if (filterValue === '' && activeCategory.id === -1) {
      const popularCategory = products.categories.find(
        (category) => category.slug === 'popular',
      );
      if (popularCategory) setActiveCategory(popularCategory);
    }
  }, [filterValue]);

  const { productsAfterSearch } = useProductsSearch({
    products: products.items,
    valueSearch: filterValue,
  });

  const productsWithPlatforms = [...productsAfterSearch];

  const uniqueProductsAfterSearch = productsWithPlatforms.reduce(
    (o: CatalogProduct[], i) => {
      if (!o.find((v: CatalogProduct) => v.id == i.id)) {
        o.push(i);
      }
      return o;
    },
    [],
  );

  const productCategoriesNew = products.categories;

  const formatLinks = <
    T extends {
      slug: string;
      customLink?: string | null;
      type?: string;
      productPlatforms?: { id: string; title: ProductPlatform; slug: string }[];
      markTitle?: string;
    },
  >(
    cards: T[],
    type: 'products' | 'solutions' | 'platforms',
  ) => {
    return cards.map((card) => {
      let link = '';
      if (card.slug) link = `/${type}/${card.slug}`;
      if ((type === 'products' || type === 'platforms') && card.customLink)
        link = `/${card.customLink}`;
      return {
        ...card,
        link,
        type: card?.type ?? type,
        productPlatforms: card.productPlatforms,
        markTitle: card?.markTitle,
      };
    });
  };

  const getProductsByCategory = (
    category: SolutionCategory | ProductCategory,
  ) => {
    const productsToShow = productsWithPlatforms?.filter((product) =>
      product.categories?.find(
        (productCategory) => productCategory.id === category.id,
      ),
    );
    const solutionsToShow = solutions.items.filter((solution) =>
      solution.categories?.find(
        (solutionCategory) => solutionCategory.id === category.id,
      ),
    );

    const productsWithLinks = formatLinks(
      productsToShow,
      category.id === 'platforms' ? 'platforms' : 'products',
    );
    const solutionsWithLinks = formatLinks(solutionsToShow, 'solutions');
    return [...solutionsWithLinks, ...productsWithLinks];
  };

  const getCardsAfterSearch = () => {
    const productsWithLinks = formatLinks(
      uniqueProductsAfterSearch,
      'products',
    );

    return [...productsWithLinks];
  };

  const onCardClick = (link: string, title: string) => {
    sendDatalayer(title);
    onClickForClose && onClickForClose();
  };

  return (
    <div className={s.root}>
      <div className={s.content}>
        {windowWidth && windowWidth <= MD && (
          <ProductsFilter
            onValueChange={(value) => setFilterValue(value)}
            value={filterValue}
          />
        )}
        {productCategoriesNew && windowWidth && windowWidth > MD && (
          <Menu
            filterValue={filterValue}
            setFilterValue={setFilterValue}
            activeCategory={activeCategory}
            setActiveCategory={setActiveCategory}
            productsCategories={productCategoriesNew}
          />
        )}
        <div className={s.items}>
          {!!filterValue ? (
            <div className={cn(s.itemsWrapper, 'header-block')}>
              {uniqueProductsAfterSearch.length === 0 ? (
                <div className={s.filterError}>
                  <h2 className={s.filterErrorTitle}>
                    По названию ничего не нашлось
                  </h2>
                </div>
              ) : (
                <>
                  <h2 className={s.title}>Результат поиска по названию</h2>
                  <div className={s.block}>
                    <div className={s.blockGrid}>
                      {getCardsAfterSearch().map((card, index) => (
                        <Card
                          key={index}
                          onClickForClose={() =>
                            onCardClick(card.link, card.title)
                          }
                          title={card.markTitle ? card.markTitle : card.title}
                          serviceTitle={
                            card.type === 'products' && card.markTitle
                              ? card.title
                              : ''
                          }
                          text={
                            card.type !== 'products' ? card.description : ''
                          }
                          icon={card.icon}
                          link={card.link}
                        />
                      ))}
                    </div>
                  </div>
                </>
              )}
            </div>
          ) : (
            <div className={cn(s.itemsWrapper, 'header-block')}>
              {[...productCategoriesNew].map((category, index) => {
                return (
                  <div
                    className={cn(s.block, {
                      [s.unactive]: activeCategory.id !== category.id,
                    })}
                    key={index}
                  >
                    <h2
                      className={s.title}
                      dangerouslySetInnerHTML={{
                        __html: category.title,
                      }}
                    />
                    {category.description && (
                      <p
                        className={s.description}
                        dangerouslySetInnerHTML={{
                          __html: category.description,
                        }}
                      />
                    )}
                    <div
                      className={cn(s.blockGrid, {
                        [s.blockGridPlatforms]: category.slug === 'platforms',
                      })}
                    >
                      {getProductsByCategory(category)?.map((card, index) => {
                        let platformName =
                          card.productPlatforms &&
                          card.productPlatforms[0].title;
                        if (
                          card.productPlatforms &&
                          card.productPlatforms.length > 1
                        )
                          platformName =
                            'Кроссплатформенный' as ProductPlatform;
                        return (
                          <Card
                            key={index}
                            platformName={platformName}
                            isPlatformCard={card.type === 'platforms'}
                            onClickForClose={() =>
                              onCardClick(card.link, card.title)
                            }
                            title={card.markTitle ? card.markTitle : card.title}
                            serviceTitle={
                              card.type === 'products' && card.markTitle
                                ? card.title
                                : ''
                            }
                            text={
                              card.type !== 'products' ? card.description : ''
                            }
                            icon={card.icon}
                            link={card.link}
                          />
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
          )}
          {filterValue.trim().length > 0 && (
            <div className={s.smartSearchLinkWrapper}>
              Расширенный поиск{' '}
              <a
                href={`/search?query=${filterValue}`}
                className={s.smartSearchLink}
                onClick={() => {
                  searchAnalytics('menu-products', filterValue);
                  if (pathname.indexOf('search') === 1) {
                    onClickForClose && onClickForClose();
                  }
                }}
              >
                по вашему запросу
              </a>
            </div>
          )}
        </div>
      </div>
      {windowWidth && windowWidth <= MD && (
        <Filter
          productsCategories={productCategoriesNew}
          activeCategory={activeCategory}
          setActiveCategory={setActiveCategory}
        />
      )}
    </div>
  );
}
