import cn from 'classnames';
import { useId } from 'react';
import { FieldValues, UseFormRegister } from 'react-hook-form';
import { InfoIcon } from 'src/components/Icons/InfoIcon';
import { useAnalytics } from 'src/hooks/useAnalytics';
import { valSchema } from 'src/utils/valSchema';

import s from './Checkbox.module.scss';

interface CheckBoxProps {
  className?: string;
  errors: any;
  name: string;
  disabled?: boolean;
  register: UseFormRegister<FieldValues>;
  required: boolean;
  checked?: boolean;
  label?: string;
  infoText?: string;
  onCustomChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  variant?: 'base' | 'dark' | 'purple';
  validationType?: 'userAgreement' | 'default';
  validationErrorText?: string;
}

function CheckIcon() {
  return (
    <svg
      className={s.icon}
      width="13"
      height="10"
      viewBox="0 0 13 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9392 1.031C11.3512 0.442996 10.4692 0.442996 9.88116 1.031L5.03014 5.88202L3.11913 3.97101C2.53113 3.38301 1.64912 3.38301 1.06112 3.97101C0.473116 4.55902 0.473116 5.44102 1.06112 6.02902L4.00113 8.96904C4.29514 9.26304 4.58914 9.41004 5.03014 9.41004C5.47114 9.41004 5.76514 9.26304 6.05914 8.96904L11.9392 3.08901C12.5272 2.50101 12.5272 1.619 11.9392 1.031Z"
        fill="#212830"
      />
    </svg>
  );
}

export function Checkbox({
  className,
  errors,
  name,
  disabled,
  register,
  checked,
  label,
  required,
  infoText,
  variant = 'base',
  onCustomChange,
  validationType = 'default',
  validationErrorText,
  ...rest
}: CheckBoxProps) {
  const { funnelFormInput } = useAnalytics();

  const { onChange, ...restOptions } = {
    ...register(name, {
      required:
        !disabled && required ? valSchema[validationType]?.required : false,
    }),
  };

  const id = useId();

  return (
    <div
      className={cn(s.root, className, {
        [s.error]: errors && errors[name],
        [s.withInfo]: infoText,
        [s.dark]: variant === 'dark',
        [s.purple]: variant === 'purple',
      })}
    >
      {infoText && (
        <>
          <InfoIcon className={s.iconInfo} />
          <span className={s.hintText}>{infoText}</span>
        </>
      )}
      <input
        data-qa="checkbox"
        checked={checked}
        data-click="funnel-form-input"
        className={s.input}
        id={name + id}
        type="checkbox"
        disabled={disabled}
        {...rest}
        {...restOptions}
        onChange={(event) => {
          onCustomChange && onCustomChange(event);
          onChange(event);
          funnelFormInput();
        }}
      />
      <div className={s.wrapper}>
        <label className={s.label} htmlFor={name + id}>
          <CheckIcon />
          {label && (
            <span
              className={s.labelText}
              dangerouslySetInnerHTML={{ __html: label }}
            />
          )}
        </label>
      </div>
      {errors && errors[name] && (
        <span className={s.errorText}>
          {validationErrorText || errors[name].message}
        </span>
      )}
    </div>
  );
}
