const Factory = ({ className }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      className={className}
    >
      <rect
        x="11.5"
        y="1.5"
        width="3"
        height="13"
        stroke="currentColor"
        strokeWidth="1.6"
      />
      <path
        d="M11.5 14.5H1.5V7.83333L7 5V7.5L11.5 4.5"
        stroke="currentColor"
        strokeWidth="1.6"
      />
      <path d="M8.5 10.5L8.5 12.1" stroke="currentColor" strokeWidth="1.6" />
      <path d="M4.5 10.5L4.5 12.1" stroke="currentColor" strokeWidth="1.6" />
    </svg>
  );
};

export { Factory };
