import cn from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { FieldValues, UseFormRegister } from 'react-hook-form';
import { QuestionIcon } from 'src/components/Icons/QuestionIcon';
import { useAnalytics } from 'src/hooks/useAnalytics';
import { useOnClickOutside } from 'src/hooks/useOnClickOutside';
import { valSchema } from 'src/utils/valSchema';

import s from './Select.module.scss';

type BaseSelectProps = React.DetailedHTMLProps<
  React.SelectHTMLAttributes<HTMLSelectElement>,
  HTMLSelectElement
> &
  SelectBase;

interface SelectBase {
  label: string;
  name: string;
  errors: any;
  hint?: { label: string; text: string };
  register: UseFormRegister<FieldValues>;
  disabled?: boolean;
  required: boolean;
  className?: string;
  options: { value: string; label: string }[];
  requiredText?: string;
}

function ArrowDown() {
  return (
    <svg
      className={s.icon}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 10.75L12 15.25L6 10.75"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function Select({
  label,
  name,
  errors,
  hint,
  register,
  required,
  disabled,
  requiredText,
  className = '',
  options,
  ...rest
}: BaseSelectProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState({
    value: '',
    label: label,
  });

  const { funnelFormInput } = useAnalytics();

  const refWrapper = useRef(null);
  const refSelect = useRef<any | null>(null);

  useOnClickOutside(refWrapper, () => setIsOpen(false));

  const { ref, onChange, ...restOptions } = register(name, {
    required: !disabled && required ? valSchema.default?.required : false,
  });

  useEffect(() => {
    if (refSelect.current && selectedOption.value !== '') {
      const handleChange = () => {
        const newEvent = new Event('change', { bubbles: true });
        refSelect.current.dispatchEvent(newEvent);
      };
      handleChange();
    }
  }, [selectedOption]);

  return (
    <div
      className={cn(s.root, className, {
        [s.error]: errors && errors[name],
      })}
    >
      <div className={cn(s.top, { [s.topVisible]: hint || requiredText })}>
        {hint && (
          <div className={s.hint}>
            <span className={s.hintLabel}>{hint.label}</span>
            <QuestionIcon />
            <div className={s.hintText}>{hint.text}</div>
          </div>
        )}
        {requiredText && <span className={s.reqText}>{requiredText}</span>}
      </div>
      <div className={s.inner}>
        <select
          value={selectedOption.value}
          className={s.selectHidden}
          ref={(event) => {
            ref(event);
            refSelect.current = event;
          }}
          onChange={(event) => {
            onChange(event);
          }}
          {...restOptions}
          {...rest}
        >
          <option disabled hidden value="">
            {label}
          </option>
          {options.map((option) => (
            <option key={option.value} value={option.value} id={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        <div
          className={s.selectWrapper}
          ref={refWrapper}
          onClick={funnelFormInput}
        >
          <div
            className={cn(s.select, {
              [s.selectOpen]: isOpen,
              [s.selectDisable]: disabled,
            })}
            onClick={() => {
              if (!disabled) {
                setIsOpen(!isOpen);
              }
            }}
          >
            <span
              className={cn(
                s.label,
                selectedOption.value === '' && s.placeholder,
              )}
            >
              {selectedOption.label}
            </span>
            <ArrowDown />
          </div>
          {isOpen && (
            <div className={s.options}>
              {options.map((option) => (
                <label
                  htmlFor={option.value}
                  key={option.value}
                  className={cn(s.option, {
                    [s.optionChosen]: option.label === selectedOption.label,
                  })}
                  onClick={() => {
                    setSelectedOption(option);
                    setIsOpen(false);
                  }}
                >
                  {option.label}
                </label>
              ))}
            </div>
          )}
        </div>

        {errors && errors[name] && (
          <span className={s.errorText}>
            {errors[name].message.length > 0
              ? errors[name].message
              : 'Обязательное поле'}
          </span>
        )}
      </div>
    </div>
  );
}
