const EvolutionPlatform = ({ className }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      className={className}
    >
      <path
        d="M15.5978 4.40209C12.5062 1.31043 7.49366 1.31043 4.402 4.40209C1.31034 7.49375 1.31034 12.5063 4.402 15.5979C7.49366 18.6896 12.5062 18.6896 15.5978 15.5979C18.6895 12.5063 18.6895 7.49375 15.5978 4.40209ZM12.1768 15.73C10.9793 16.9276 9.01999 16.9276 7.82243 15.73L4.2699 12.1775C3.07234 10.9799 3.07234 9.02066 4.2699 7.82311L7.82185 4.27116C9.01941 3.0736 10.9787 3.0736 12.1762 4.27116L15.7288 7.82369C16.9263 9.02125 16.9263 10.9805 15.7288 12.1781L12.1768 15.73Z"
        fill="currentColor"
      />
    </svg>
  );
};

export { EvolutionPlatform };
